import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

//components
import Step from "../../components/BikeInsuranceForm/Step";
import Stepper from "../../components/BikeInsuranceForm/Stepper";

//helpers & apis
import { formatAmountInRupees } from "../../helper/utils";
import { getVehicleDetails } from "../../apis/verificationApis";

//assets
import digitLogo from "../../assets/digit.svg";
import Discount from "../../assets/discount.svg";
import hdfcLogo from "../../assets/hdfcErgoLogo.jpg";

export const BikeInsuranceFormContext = createContext();

const BikeInsuranceProposal = () => {
  const location = useLocation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const {
    planData,
    vehicleInfo,
    vehicleNo,
    newDigitVehicleCode,
    newHdfcBikeCode,
    policyTypeNameCTO,
    kycInfo,
    insuranceType,
    ncbValue,
    madeClaim,
    toolbarOptions,
    previousPolicyType,
    vehicleMake,
    vehicleModel,
    vehicleVariant,
    hdfcPehchanId,
    hdfcBikeValue,
  } = location.state;

  const voluntaryDeductibleDiscount = toolbarOptions.voluntaryExcess;
  const shouldRenderVehicleDetails = vehicleNo.length > 4;
  const [formData, setFormData] = useState({
    registrationnumber: vehicleNo,
    digitVehicleCode: vehicleInfo?.digitVehicleCode || newDigitVehicleCode,
    hdfcVehicleCode: vehicleInfo?.hdfcBikeCode || newHdfcBikeCode,
    hdfcPehchanId: hdfcPehchanId || "",
    hdfcBikeValue: hdfcBikeValue || "",
    policyTypeNameCTO,
    registrationDate: "",
    enginenumber: "",
    chasisnumber: "",
    customername: kycInfo.name,
    email: kycInfo.email ?? "",
    mobilenumber: kycInfo.mobileNumber,
    gender:
      kycInfo.gender === "M" ? "Male" : kycInfo.gender === "F" ? "Female" : "",
    maritalstatus: "",
    dob: kycInfo.dob,
    pannumber: kycInfo.panNumber,
    gstnumber: "",
    policyEndDate: "",
    nomineename: "",
    nomineedob: "",
    nomineerelation: "",
    registeredaddress: "",
    pincode: "",
    city: "",
    state: "",
    previousinsurer: "",
    previouspolicynumber: "",
    previousPolicyExpiryDate: "",
    puc: "",
    insuranceType,
    ncbValue,
    madeClaim,
    voluntaryDeductibleDiscount,
    previousPolicyType,
    vehicleMake,
    vehicleModel,
    vehicleVariant,
  });

  let imgSrc;
  switch (planData.INSURER_NAME) {
    // case "SHRIRAM":
    //   imgSrc = ShriramGi;
    //   break;
    case "GODIGIT":
      imgSrc = digitLogo;
      break;
    case "HDFC":
      imgSrc = hdfcLogo;
      break;
    default:
      imgSrc = Discount;
      break;
  }

  const fetchVehicleDetails = useCallback(async () => {
    const res = await getVehicleDetails(vehicleNo.split("-").join(""));
    const vehicleDetails = res.data.vahanData;
    if (res.ok) {
      const address = vehicleDetails.present_address;
      const addressParts = address.split(",");
      let pincode = addressParts[addressParts.length - 1].trim();
      pincode = pincode.replace(/\D/g, "").substring(0, 6);
      const state = addressParts[addressParts.length - 2].trim();
      const city = addressParts[addressParts.length - 3].trim();

      const details = {
        customername: vehicleDetails.owner_name,
        mobilenumber: vehicleDetails.mobile_number,
        registeredaddress: address,
        pincode: pincode,
        city: city,
        state: state,
        previousinsurer: vehicleDetails.insurance_company,
        previouspolicynumber: vehicleDetails.insurance_policy_number,
        previousPolicyExpiryDate:
          insuranceType !== "OD only"
            ? vehicleDetails.insurance_upto
            : vehicleInfo.odPolicyExpiredate,
        registrationDate: vehicleDetails.registration_date,
        enginenumber: vehicleDetails.vehicle_engine_number,
        chasisnumber: vehicleDetails.vehicle_chasi_number,
        puc: vehicleDetails.pucc_number,
      };
      // console.log(details);
      setFormData((prev) => {
        return { ...prev, ...details };
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to fetch Vehicle details from Vahan Apis",
        text: res?.data,
      });
      console.log(res);
    }
  }, [vehicleNo, insuranceType, vehicleInfo]);

  const bikeInsuranceFormContextValue = useMemo(
    () => ({
      activeStepIndex,
      setActiveStepIndex,
      formData,
      setFormData,
      fetchVehicleDetails,
      vehicleData: vehicleInfo,
      planData: planData,
    }),
    [
      activeStepIndex,
      setActiveStepIndex,
      formData,
      setFormData,
      fetchVehicleDetails,
      vehicleInfo,
      planData,
    ]
  );

  // useEffect(() => {
  //   fetchVehicleDetails();
  // }, [fetchVehicleDetails]);

  useEffect(() => {
    if (shouldRenderVehicleDetails) {
      fetchVehicleDetails();
    }
  }, [shouldRenderVehicleDetails, fetchVehicleDetails]);

  return (
    <div className="bg-gradient-to-b from-[#faf5ea] to-transparent lg:flex lg:justify-around lg:basis-3/4 lg:p-10 p-5 lg:px-20 lg:py-10 lg:space-x-16">
      <section>
        <BikeInsuranceFormContext.Provider
          value={bikeInsuranceFormContextValue}
        >
          <div className="lg:flex lg:flex-col lg:space-y-4">
            <Stepper shouldRenderVehicleDetails={shouldRenderVehicleDetails} />
            <Step shouldRenderVehicleDetails={shouldRenderVehicleDetails} />
          </div>
        </BikeInsuranceFormContext.Provider>
      </section>

      <section className="hidden lg:flex lg:basis-1/4 lg:mt-[5.5rem]">
        <article>
          <div className="flex flex-col rounded-2xl bg-white border-2 items-center shadow-lg p-5">
            <img
              className="rounded-lg h-20 w-40 object-cover p-4"
              src={imgSrc}
              alt="companyLogo"
            />
            <div className="m-2">
              <p className="text-[#00000094]">
                IDV Cover :{" "}
                {/* <span className="font-medium text-black">Rs 8,45,514</span> */}
                <span className="font-medium text-black">
                  INR {planData?.carValue}
                </span>
              </p>
            </div>
            <div className="m-2">
              <p className="text-[#00000094]">
                Plan type :{" "}
                <span className="font-medium text-black">
                  {planData.policyTypeCTO}
                </span>
              </p>
            </div>
            <div className="w-full border-t border-gray-300" />
            <div className="m-2">
              <p className="text-[#00000094]">
                Premium Amount :{" "}
                {/* <span className="font-medium text-black">Rs 140000</span> */}
                <span className="font-medium text-black">
                  {planData.totalPremium}
                </span>
              </p>
            </div>
            <div className="m-2">
              <p className="text-[#00000094]">
                GST @18% :{" "}
                {/* <span className="font-medium text-black">+ Rs 521</span> */}
                <span className="font-medium text-black">
                  + {planData.totalTax}
                </span>
              </p>
            </div>

            <div className="w-full border-t border-gray-300 mb-5" />
            {/* <div className="bg-[#9D0004] w-full text-white text-center font-medium rounded-xl p-3">
                Rs 14,521
              </div> */}
            <div className="bg-[#9D0004] w-full text-white text-center font-medium rounded-xl p-3">
              {formatAmountInRupees(planData.totalAmount)}
            </div>
          </div>
        </article>
      </section>
    </div>
  );
};

export default BikeInsuranceProposal;
