import axios from "axios";
import apis from "./allApis";

const getWithoutToken = async (endPoint) => {
  return await axios.get(`${apis.baseUrl}${endPoint}`);
};

const getWithToken = async (endPoint) => {
  const token = localStorage.getItem("token");
  const getTokenConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.get(`${apis.baseUrl}${endPoint}`, getTokenConfig);
};

const postWithToken = async (endPoint, body) => {
  const token = localStorage.getItem("token");
  const postTokenConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.post(
    `${apis.baseUrl}${endPoint}`,
    JSON.stringify(body),
    postTokenConfig
  );
};

const postWithoutToken = async (endPoint, body) => {
  const postConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${apis.baseUrl}${endPoint}`,
    JSON.stringify(body),
    postConfig
  );
};

export { getWithToken, getWithoutToken, postWithToken, postWithoutToken };
