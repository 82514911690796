// Stepper.js
import React, { useContext, useEffect } from "react";
import { BikeInsuranceFormContext } from "../../pages/BikeInsurance/BikeInsuranceProposal";

function Stepper({ shouldRenderVehicleDetails }) {
  const { activeStepIndex } = useContext(BikeInsuranceFormContext);

  useEffect(() => {
    const stepperItems = document.querySelectorAll(".stepper-item");
    stepperItems.forEach((step, i) => {
      if (i <= activeStepIndex) {
        step.classList.add("bg-[#9D0004]", "text-white");
        step.classList.remove("bg-[#D9D9D9]", "text-black");
      } else {
        step.classList.remove("bg-[#9D0004]", "text-white");
        step.classList.add("bg-[#D9D9D9]", "text-black");
      }
    });

    const stepperLine = document.querySelectorAll(".stepper-line");
    stepperLine.forEach((step, i) => {
      if (i <= activeStepIndex) {
        step.classList.add("border-[#9D0004]");
      } else {
        step.classList.remove("border-[#9D0004]");
      }
    });
  }, [activeStepIndex]);

  return (
    <>
      <div className="flex w-full flex-col space-y-4 items-center">
        <div className="flex w-full justify-between">
          <p className="w-44 text-[#75869C]">Basic Details</p>
          <p className="w-44 text-center text-[#75869C]">Nominee Details</p>
          <p className="w-44 text-end text-[#75869C]">Address</p>
          {/* <p className="w-44 text-end text-[#75869C]">Vehicle Details</p> */}
          {shouldRenderVehicleDetails && (
            <p className="w-44 text-end text-[#75869C]">Vehicle Details</p>
          )}
        </div>

        <div className="flex w-full items-center justify-center lg:pb-0 pb-4">
          <div className="stepper-item w-8 h-8 text-center pt-1 font-medium bg-[#D9D9D9] rounded-full">
            1
          </div>
          <div className="stepper-line flex-auto border-t-4"></div>
          <div className="stepper-item w-8 h-8 text-center pt-1 font-medium bg-[#D9D9D9] rounded-full">
            2
          </div>
          <div className="stepper-line flex-auto border-t-4"></div>
          <div className="stepper-item w-8 h-8 text-center pt-1 font-medium bg-[#D9D9D9] rounded-full">
            3
          </div>
          {/* <div className="stepper-line flex-auto border-t-4"></div> */}
          {/* <div className="stepper-item w-8 h-8 text-center pt-1 font-medium bg-[#D9D9D9] rounded-full">
            4
          </div> */}
          {shouldRenderVehicleDetails && (
            <div className="stepper-line flex-auto border-t-4"></div>
          )}
          {shouldRenderVehicleDetails && (
            <div className="stepper-item w-8 h-8 text-center pt-1 font-medium bg-[#D9D9D9] rounded-full">
              4
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Stepper;
