import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import moment from "moment";

// assets
import InfoIcon from "../../assets/Info.svg";

const BikeInsuranceSummary = () => {
  const isMobile = window.innerWidth <= 767;

  const navigate = useNavigate();
  const location = useLocation();

  console.log(location.state);
  const data = location.state?.data || "";
  const { registration_date, insurance_company, insurance_upto } = data;

  const ncb = [
    { val: "ZERO", name: "0%" },
    { val: "TWENTY", name: "20%" },
    { val: "TWENTY_FIVE", name: "25%" },
    { val: "THIRTY_FIVE", name: "35%" },
    { val: "FORTY_FIVE", name: "45%" },
    { val: "FIFTY", name: "50%" },
  ];
  const policyInsurer = [
    { val: "ACKO", name: "Acko General Insurance Limited" },
    { val: "HDFC", name: "HDFC ERGO General Insurance Co. Ltd" },
    { val: "GODIGIT", name: "GoDigit General Insurance Limited" },
    { val: "SHRIRAM", name: "Shriram General Insurance Company Limited" },
    { val: "ICICI", name: "ICICI Lombard General Insurance Co. Ltd" },
    {
      val: "LIBERTY",
      name: "Liberty Videocon General Insurance Company Limited",
    },
    { val: "IFFCO", name: "IFFCO Tokio General Insurance Co. Ltd" },
    { val: "TATA", name: "Tata AIG General Insurance Co. Ltd." },
    { val: "BAJAJ", name: "Bajaj Allianz General Insurance Co. Ltd" },
    { val: "BHARTI", name: "Bharti AXA General Insurance Company Limited" },
    { val: "CHOLAMS", name: "Cholamandalam MS General Insurance Co. Ltd" },
    { val: "DHFL", name: "DHFL General Insurance Limited" },
    { val: "EDELWEISS", name: "Edelweiss General Insurance" },
    { val: "FUTURE", name: "Future Generali India Insurance Company Limited" },
    { val: "KOTAK", name: "Kotak Mahindra General Insurance Company Limited" },
    { val: "NATIONAL", name: "National Insurance Co. Ltd" },
    { val: "RAHEJA", name: "Raheja QBE General Insurance Company Limited" },
    { val: "RELIANCE", name: "Reliance General Insurance Co. Ltd" },
    { val: "MAGMA", name: "Magma HDI General Insurance Company Limited" },
    { val: "SBI", name: "SBI General Insurance Company Limited" },
    { val: "ORIENTAL", name: "The Oriental Insurance Co. Ltd" },
    { val: "UNIVERSAL", name: "Universal Sompo General Insurance Co. Ltd" },
    { val: "ROYAL", name: "Royal Sundaram General Insurance Co. Limited" },
    { val: "OTHERS", name: "Others  Not available" },
  ];

  const tpExpDate = moment(insurance_upto).add(2, "year").format("YYYY-MM-DD");

  const [formData, setFormData] = useState({
    registrationType: "",
    preferredPolicy: "",
    registrationDate: registration_date,
    hasInsurance: "",
    prevPolicyExpiredate: insurance_upto,
    odPolicyExpiredate: "dd-mm-yyyy",
    tpPolicyExpiredate: tpExpDate,
    previousInsurer: insurance_company.split(" ")[0].toUpperCase(),
    tpPolicyInsurer: "",
    claimInPolicy: "",
    ncb: "ZERO",
  });
  const [madeClaim, setMadeClaim] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const shouldCalculateNcb = value === "No";

    if (name === "claimInPolicy" && value === "Yes") {
      setMadeClaim(true);
    } else {
      setMadeClaim(false);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      shouldCalculateNcb,
    }));
  };

  useEffect(() => {
    if (formData.shouldCalculateNcb) {
      // Calculate Claim-Free Years
      const registrationYear = new Date(
        formData.registrationDate
      ).getFullYear();
      const policyExpireYear = new Date(
        formData.prevPolicyExpiredate
      ).getFullYear();
      const claimFreeYears = policyExpireYear - registrationYear;

      // Determine NCB based on claim-free years
      let ncbValue = "ZERO"; // default value for 0% NCB

      if (claimFreeYears >= 6) {
        ncbValue = "FIFTY"; // 50% NCB for 6 or more claim-free years
      } else if (claimFreeYears === 5) {
        ncbValue = "FORTY_FIVE";
      } else if (claimFreeYears === 4) {
        ncbValue = "THIRTY_FIVE";
      } else if (claimFreeYears === 3) {
        ncbValue = "TWENTY_FIVE";
      } else if (claimFreeYears === 2) {
        ncbValue = "TWENTY";
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        ncb: ncbValue,
      }));
    }
  }, [
    formData.registrationDate,
    formData.prevPolicyExpiredate,
    formData.shouldCalculateNcb,
  ]);

  const selectedNCB = ncb.find((item) => item.val === formData.ncb);

  const handleDetailsSubmit = () => {
    const insuranceType = formData.preferredPolicy;
    const previousPolicyType = formData.previousPolicyType;
    const ncbValue = formData.ncb;
    // formData.digitVehicleCode = location.state.vehicleCode;
    formData.digitVehicleCode = location.state.digitVehicleCode;
    formData.hdfcBikeCode = location.state.hdfcBikeCode;
    navigate("../quotes", {
      state: {
        formData,
        data,
        insuranceType,
        previousPolicyType,
        ncbValue,
        selectedNCB: selectedNCB.name,
        madeClaim,
      },
    });
  };
  return (
    <section>
      <article className="bg-gradient-to-b from-[#faf5ea] to-transparent">
        <form onSubmit={handleDetailsSubmit}>
          <div className="lg:mb-20 p-4 lg:p-10 lg:flex lg:justify-center">
            <div className="lg:mt-0 mt-5 lg:flex lg:flex-col w-full lg:w-3/5 lg:p-8 p-4 border-2 rounded-2xl bg-white shadow-lg">
              <p className="font-medium text-[#9D0004] lg:text-3xl text-xl lg:mb-10 mb-5">
                Confirm Details
              </p>
              <div className="lg:flex lg:flex-col lg:w-full lg:space-y-10 space-y-4">
                <div className="lg:flex">
                  <p className="lg:flex lg:basis-1/3 font-medium">
                    Bike is registered on
                  </p>
                  <div className="lg:flex lg:basis-2/3 lg:space-x-8">
                    <div className="lg:flex lg:items-center lg:space-x-2 lg:m-0 m-1">
                      <input
                        type="radio"
                        name="registrationType"
                        value="individual"
                        required
                        checked={formData.registrationType === "individual"}
                        className="w-5 h-5 rounded-lg lg:mr-0 mr-2"
                        onChange={handleChange}
                      />
                      <label>Individual</label>
                    </div>
                    <div className="lg:flex lg:items-center lg:space-x-2 lg:m-0 m-1">
                      <input
                        type="radio"
                        name="registrationType"
                        required
                        value="company"
                        checked={formData.registrationType === "company"}
                        className="w-5 h-5 rounded-lg lg:mr-0 mr-2"
                        onChange={handleChange}
                      />
                      <label>Company</label>
                    </div>
                  </div>
                </div>
                <div className="lg:flex">
                  <p className="lg:flex lg:basis-1/3 font-medium">
                    Select your preferred policy
                  </p>
                  <div className="lg:flex lg:basis-2/3 lg:space-x-8 items-center">
                    <div className="lg:flex lg:items-center lg:space-x-2 lg:m-0 m-1">
                      <input
                        type="radio"
                        name="preferredPolicy"
                        value="TP only"
                        required
                        checked={formData.preferredPolicy === "TP only"}
                        className="w-5 h-5 rounded-lg lg:mr-0 mr-2"
                        onChange={handleChange}
                      />
                      <label>TP only</label>
                    </div>
                    <div className="lg:flex lg:items-center lg:space-x-2 lg:m-0 m-1">
                      <input
                        type="radio"
                        name="preferredPolicy"
                        value="OD only"
                        required
                        checked={formData.preferredPolicy === "OD only"}
                        className="w-5 h-5 rounded-lg lg:mr-0 mr-2"
                        onChange={handleChange}
                      />
                      <label>OD only</label>
                    </div>
                    <div className="lg:flex lg:items-center lg:space-x-2 lg:m-0 m-1">
                      <input
                        type="radio"
                        name="preferredPolicy"
                        required
                        value="Comprehensive"
                        checked={formData.preferredPolicy === "Comprehensive"}
                        className="w-5 h-5 rounded-lg lg:mr-0 mr-2"
                        onChange={handleChange}
                      />
                      <label>Comprehensive</label>
                    </div>
                  </div>
                </div>
                <div className="lg:flex">
                  <p className="lg:flex lg:basis-1/3 font-medium">
                    Previous policy details
                  </p>
                  <div className="lg:grid lg:grid-cols-2 lg:gap-y-[2rem] lg:basis-2/3 lg:gap-x-[1rem]">
                    {/* Expiry Date or TP policy Expiry date */}
                    <div>
                      <div className="relative">
                        {formData.preferredPolicy !== "OD only" ? (
                          <label className="text-gray-900 absolute top-[-10px] text-[14px] left-[10px] px-[6px] bg-white w-[88px]">
                            Expiry Date
                          </label>
                        ) : (
                          <label className="text-gray-900 absolute top-[-10px] text-[14px] left-[10px] px-[6px] bg-white w-[200px]">
                            TP policy Expiry date
                          </label>
                        )}
                      </div>
                      {formData.preferredPolicy !== "OD only" ? (
                        <div className="w-full flex items-center border-2 rounded-md p-1 lg:mb-0 mb-4">
                          <input
                            className="w-full p-1 border-1 outline-none"
                            type="date"
                            required
                            id="prevPolicyExpiredate"
                            name="prevPolicyExpiredate"
                            value={formData.prevPolicyExpiredate}
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        <div className="w-full flex items-center border-2 rounded-md p-1 lg:mb-0 mb-4">
                          <input
                            className="w-full p-1 border-1 outline-none"
                            type="date"
                            required
                            id="tpPolicyExpiredate"
                            name="tpPolicyExpiredate"
                            value={formData.tpPolicyExpiredate}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>

                    {/* Previous Insurer */}
                    <div>
                      <div className="relative">
                        <label className="text-gray-900 absolute top-[-10px] text-[14px] left-[10px] px-[6px] bg-white w-[120px]">
                          Previous Insurer
                        </label>
                      </div>
                      <div className="w-full lg:mt-0 mt-2 flex items-center border-2 rounded-md p-1">
                        <select
                          className="w-full p-1 border-1 outline-none"
                          name="previousInsurer"
                          required
                          value={formData.previousInsurer}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              previousInsurer: e.target.value,
                            })
                          }
                        >
                          <option key={"selected"} value={0}>
                            Select Insurer
                          </option>
                          {policyInsurer.map((insurer) => (
                            <option key={insurer.val} value={insurer.val}>
                              {insurer.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* OD Policy Expiry Date */}
                    <div
                      className={`${
                        formData.preferredPolicy === "OD only"
                          ? "flex col-span-1"
                          : "hidden"
                      }`}
                    >
                      <div className="relative">
                        <label className="text-gray-900 absolute top-[-10px] text-[14px] left-[10px] px-[6px] bg-white w-[200px]">
                          OD Policy Expiry Date
                        </label>
                      </div>
                      <div className="w-full flex items-center border-2 rounded-md p-1 lg:mb-0 mb-4">
                        <input
                          className="w-full p-1 border-1 outline-none"
                          type="date"
                          required={formData.preferredPolicy === "OD only"}
                          id="odPolicyExpiredate"
                          name="odPolicyExpiredate"
                          value={formData.prevPolicyExpiredate}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    {/* Previous Policy Type */}
                    {formData.preferredPolicy === "OD only" && (
                      <div className={`flex col-span-1`}>
                        <div className="relative">
                          <label className="text-gray-900 absolute top-[-10px] text-[14px] left-[10px] px-[6px] bg-white w-[170px]">
                            Previous Policy Type
                          </label>
                        </div>
                        <div className="w-full flex items-center border-2 rounded-md p-2">
                          <select
                            className="w-full p-1 border-1 outline-none"
                            name="previousPolicyType"
                            value={formData.previousPolicyType}
                            onChange={handleChange}
                          >
                            <option value="1OD + 1TP">1OD + 1TP</option>
                            <option value="0OD + 1TP">0OD + 1TP</option>
                            <option value="1OD + 3TP">1OD + 5TP</option>
                            <option value="0OD + 3TP">0OD + 5TP</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`${
                    // formData.hasInsurance === "Yes" &&
                    formData.preferredPolicy !== "TP only"
                      ? "lg:flex"
                      : "hidden"
                  }`}
                >
                  <p className="lg:flex lg:basis-1/3 font-medium">
                    Made any claim in the previous policy?{""}
                    <span>
                      {/* Tooltip component */}
                      <button
                        type="button"
                        onClick={() => {}}
                        data-tooltip-id="my-tooltip-data-html"
                        data-tooltip-html="<div><h1>No Claim Bonus (NCB):</h1><ul><li>NCB is a discount provided to policyholders who haven't filed any claims during their policy period.</li><br /><li>It's a reward for safe driving. Keep in mind, if you raise a claim during your policy tenure, the NCB benefit will be reset.</li></ul></div>"
                        // data-tooltip-content="No Claim Bonus (NCB): NCB is a discount provided to policyholders who haven't filed any claims during their policy period. It's a reward for safe driving. Keep in mind, if you raise a claim during your policy tenure, the NCB benefit will be reset."
                        data-tooltip-place={
                          isMobile ? "right-start" : "bottom-end"
                        }
                        data-tooltip-delay-hide={200}
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                      >
                        <img
                          className="lg:w-6 lg:h-6 w-4 h-4"
                          src={InfoIcon}
                          alt=""
                        />
                      </button>
                      <Tooltip
                        // id="my-tooltip"
                        id="my-tooltip-data-html"
                        style={{
                          backgroundColor: "#FFCDCF",
                          color: "black",
                          width: `${isMobile ? "50%" : "20%"}`,
                        }}
                      />
                    </span>
                  </p>
                  <div className="lg:grid lg:basis-2/3 lg:grid-cols-2 lg:gap-y-[2rem] lg:gap-x-[1rem]">
                    <div className="lg:flex lg:space-x-8 lg:mb-0 mb-4">
                      <div className="lg:flex lg:items-center lg:space-x-2 lg:m-0 m-1">
                        <input
                          type="radio"
                          name="claimInPolicy"
                          value="Yes"
                          checked={formData.claimInPolicy === "Yes"}
                          className="w-5 h-5 rounded-lg lg:mr-0 mr-2"
                          onChange={handleChange}
                        />
                        <label>Yes</label>
                      </div>
                      <div className="lg:flex lg:items-center lg:space-x-2 lg:m-0 m-1">
                        <input
                          type="radio"
                          name="claimInPolicy"
                          value="No"
                          checked={formData.claimInPolicy === "No"}
                          className="w-5 h-5 rounded-lg lg:mr-0 mr-2"
                          onChange={handleChange}
                        />
                        <label>No</label>
                      </div>
                    </div>
                    <div
                      className={`${
                        formData.claimInPolicy === "No" ? "flex" : "hidden"
                      }`}
                    >
                      {/* <div className="relative"> */}
                      <label className="text-gray-900  text-sm font-medium bg-white w-[120px]">
                        Previous Policy NCB
                      </label>
                      {/* </div> */}
                      <div className="w-full lg:mt-0 mt-2 flex items-center border-2 rounded-md p-1">
                        <select
                          className="w-full p-1 border-1 outline-none"
                          name="ncb"
                          value={formData.ncb}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              ncb: e.target.value,
                            })
                          }
                        >
                          {ncb.map((ncb) => (
                            <option key={ncb.val} value={ncb.val}>
                              {ncb.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-[#9D0004] text-white font-medium rounded-2xl lg:p-3 p-2"
                  >
                    Proceed to Quotes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </article>
    </section>
  );
};

export default BikeInsuranceSummary;
