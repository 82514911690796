import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { bikeKycStatus } from "../../apis/bikeApis";

const BikeRedirection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const insName = searchParams.get("INSURER_NAME");
  const policyId = searchParams.get("policyId");

  const [isKycVerified, setIsKycVerified] = useState(false);
  const [kycLink, setKycLink] = useState();

  useEffect(() => {
    const fetchKYCLink = async () => {
      const res = await bikeKycStatus({
        policyId: policyId,
        INSURER_NAME: insName,
      });
      console.log(res);
      if (res.ok) {
        if (res.data?.kycStatus) {
          setIsKycVerified(true);
          Swal.fire({
            icon: "error",
            title: `KYC Already verified for Policy Id - ${policyId}`,
          });
        } else {
          // window.open(res.data.link);
          setKycLink(res.data);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Not able to fetched KYC link",
        });
      }
    };
    if (!policyId) {
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
      });
    } else {
      fetchKYCLink();
    }
  }, [insName, policyId]);

  const handleKyc = (e) => {
    e.preventDefault();
    window.open(kycLink, "_blank");
    Swal.fire({
      title: "Have you Completed KYC?",
      // text: "You won't be able to revert this!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/bikeinsurance/purchasesummary", {
          state: {
            INSURER_NAME: insName,
            policyId,
          },
        });
      }
    });
  };

  const handleDetails = (e) => {
    e.preventDefault();
    navigate("/bikeinsurance/purchasesummary", {
      state: {
        INSURER_NAME: insName,
        policyId,
      },
    });
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {isKycVerified ? (
        <button
          className="p-5 solid bg-[#9D0004] text-white rounded-lg font-medium"
          onClick={handleDetails}
        >
          Check Policy Details
        </button>
      ) : (
        <button
          className="p-5 solid bg-[#9D0004] text-white rounded-lg font-medium"
          onClick={handleKyc}
        >
          Complete KYC
        </button>
      )}
    </div>
  );
};

export default BikeRedirection;
