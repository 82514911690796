import React, { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

//apis
import { getVehicleCodeFromDb } from "../../../apis/verificationApis";

//assets
import CloseIcon from "../../../assets/closeIcon.svg";

const ModalWithBikeNumber = ({ data, closeModal }) => {
  const navigate = useNavigate();
  console.log(data);

  const { make, model, variant, vehicleFuelType, cc, seating_capacity } = data;
  const {
    body_type,
    fuel_type,
    registration_date,
    registered_at,
    manufacturing_date,
  } = data.vahanData;

  const [formData, setFormData] = useState({
    manufacturingMonth: manufacturing_date?.split("/")[0],
    manufacturingYear: manufacturing_date?.split("/")[1],
  });

  const months = [
    { val: 1, name: "January" },
    { val: 2, name: "February" },
    { val: 3, name: "March" },
    { val: 4, name: "April" },
    { val: 5, name: "May" },
    { val: 6, name: "June" },
    { val: 7, name: "July" },
    { val: 8, name: "August" },
    { val: 9, name: "September" },
    { val: 10, name: "October" },
    { val: 11, name: "November" },
    { val: 12, name: "December" },
  ];

  const [bikeInfo, setBikeInfo] = useState({
    bikeModel: `${make?.split(" ")[0]} - ${
      model?.split(" ")[0]
    } - ${variant} - ${body_type} - ${fuel_type}`,
    registerAt: registered_at,
    registerDate: registration_date,
  });

  const mfYrs = manufacturing_date?.split("/")[1];
  const years = [+mfYrs - 2, +mfYrs - 2 - 1, +mfYrs, +mfYrs + 1, +mfYrs + 2];

  const handleManufacturingMonthChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      manufacturingMonth: value,
    }));
  };

  const handleManufacturingYearChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      manufacturingYear: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await getVehicleCodeFromDb({
        make: make.toUpperCase(),
        model: model,
        fuelType: vehicleFuelType,
        variant: variant,
        cubicCapacity: cc,
        seatingCapacity: seating_capacity,
      });

      if (res.ok) {
        const digitVehicleCode =
          res.data.digitVehicleCodeForVariant[0]?.vehicleCode;
        let hdfcBikeCode = "";
        if (res.data.hdfcBikeCodeForVariant?.VEHICLEMODELCODE) {
          hdfcBikeCode =
            res.data.hdfcBikeCodeForVariant?.VEHICLEMODELCODE?.toString();
        }

        // Check if any of the three parameters is available
        if (digitVehicleCode || hdfcBikeCode) {
          console.log("Digit Vehicle Code:", digitVehicleCode);
          console.log("HDFC Vehicle Code:", hdfcBikeCode);

          navigate("summary", {
            state: {
              bikeInfo: { ...bikeInfo },
              data: data.vahanData,
              digitVehicleCode,
              hdfcBikeCode,
              formData,
            },
          });
        } else {
          console.log("Vehicle Code not available");
          Swal.fire({
            icon: "error",
            title:
              "Vehicle Code missing - Please contact K Wasan Insurance team at 022 6683 2222 to process your insurance need in minutes",
          });
        }
      } else {
        console.log(res);
        Swal.fire({
          icon: "error",
          title:
            "Vehicle Code missing - Please contact K Wasan Insurance team at 022 6683 2222 to process your insurance need in minutes",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="overflow-hidden">
      <div className="flex lg:justify-evenly justify-around">
        <h2 className="lg:text-3xl text-base font-medium text-[#9D0004]">
          Continue With Bike Number
        </h2>
        <button onClick={closeModal}>
          <img className="lg:w-7 lg:h-7 w-5 h-6" src={CloseIcon} alt="" />
        </button>
      </div>

      <div className="flex justify-center lg:m-10 m-5">
        <form onSubmit={handleSubmit}>
          <label className="text-[#4B4B4B]" htmlFor="bikedetails">
            Bike Details
          </label>
          <input
            className="w-full bg-slate-50 outline-none mt-2 mb-4 p-2 border-2 rounded-xl"
            id="bikedetails"
            name="bikedetails"
            type="text"
            placeholder="Bike Details"
            value={bikeInfo.bikeModel}
            onChange={(e) => {
              setBikeInfo((prev) => {
                return { ...prev, bikeModel: e.target.value };
              });
            }}
          />

          <label className="text-[#4B4B4B]">Registration Details</label>
          <div className="flex lg:flex-row flex-col">
            <input
              className="lg:w-3/5 w-full bg-slate-50 outline-none mt-2 mb-4 mr-4 p-2 border-2 rounded-xl"
              id="registrationdetails"
              name="registrationdetails"
              type="text"
              placeholder="Registration Details"
              value={bikeInfo.registerAt}
              onChange={(e) => {
                setBikeInfo((prev) => {
                  return { ...prev, registerAt: e.target.value };
                });
              }}
            />
            <input
              className="lg:w-2/5 w-3/4 bg-slate-50 outline-none mt-2 mb-4 p-2 border-2 rounded-xl"
              id="date"
              name="date"
              type="date"
              placeholder="Date"
              value={bikeInfo.registerDate}
              onChange={(e) => {
                setBikeInfo((prev) => {
                  return {
                    ...prev,
                    registerDate: e.target.value,
                  };
                });
              }}
            />
          </div>
          <label className="text-[#4B4B4B]">Manufacturing Date</label>
          <div className="flex mt-2 lg:mb-4">
            <div className="flex items-center border-2 rounded-md p-2">
              <select
                className="w-28 border-0 outline-none"
                name="manufacturingMonth"
                value={formData.manufacturingMonth}
                required
                onChange={handleManufacturingMonthChange}
              >
                {months.map((month) => (
                  <option key={month.val} value={month.val}>
                    {month.name}
                  </option>
                ))}
              </select>
              <select
                className="w-24 border-0 outline-none"
                name="manufacturingYear"
                value={formData.manufacturingYear}
                required
                onChange={handleManufacturingYearChange}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="lg:mt-8 mt-10 p-2 w-full font-medium lg:text-lg text-base text-white bg-[#9D0004] rounded-xl"
          >
            View Quote
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModalWithBikeNumber;
