import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "../App.css";
import { Collapse, initTE } from "tw-elements";
import Swal from "sweetalert2";

import WasanLogo from "../assets/WasanLogo.svg";
import NavDropdown from "../components/NavDropdown";
// import LoginDropdown from "../components/LoginDropdown";

const Navbar = () => {
  const navigate = useNavigate();
  useEffect(() => {
    initTE({ Collapse });
  }, []);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const handleLogout = () => {
    Swal.fire({
      icon: "info",
      title: "Are you sure you want to Log Out?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#9D0004",
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear user data from local storage
        localStorage.removeItem("userData");
        // Redirect to home page or login page
        navigate("/");
      }
    });
  };

  const handleDashboardClick = () => {
    // Check if user is logged in, then open the Dashboard link
    if (userData) {
      // window.location.href =
      //   "http://34.100.153.108/SAIBAOnline/Login/AdminLogin.aspx";
      window
        .open(
          "http://34.100.153.108/SAIBAOnline/Login/AdminLogin.aspx",
          "_blank"
        )
        .focus();
    }
  };

  return (
    <>
      <nav
        className="relative flex w-full flex-wrap items-center justify-between bg-[#FFFFFF] py-2 text-black shadow-lg hover:text-[#9D0004] focus:text-black lg:py-4"
        data-te-navbar-ref
      >
        <div className="flex w-full flex-wrap items-center justify-between px-3">
          <div>
            <a className="mx-2 my-1 flex items-center lg:mb-0 lg:mt-0" href="/">
              <img
                className="mr-2"
                src={WasanLogo}
                style={{ height: 62 }}
                alt="Wasan Logo"
                loading="lazy"
              />
            </a>
          </div>

          <button
            className="block border-0 bg-transparent px-2 text-black hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent4"
            aria-controls="navbarSupportedContent4"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-7 w-7"
              >
                <path d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" />
              </svg>
            </span>
          </button>

          <div
            className="!visible mt-2 hidden justify-center flex-grow basis-[100%] items-center lg:mt-0 lg:!flex lg:basis-auto"
            id="navbarSupportedContent4"
            data-te-collapse-item
          >
            <ul
              className="list-style-none mr-auto ml-auto flex flex-col pl-0 lg:mt-1 lg:flex-row"
              data-te-navbar-nav-ref
            >
              <li
                className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1"
                data-te-nav-item-ref
              >
                <NavLink aria-current="page" data-te-nav-link-ref to="/">
                  <span className="text-sm sm:text-sm md:text-base lg:text-base xl:text-lg text-black hover:text-[#9D0004] focus:text-black disabled:text-black/30 lg:px-2 [&.active]:text-black/90">
                    Home
                  </span>
                </NavLink>
              </li>

              <div className="relative my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1 text-sm sm:text-sm md:text-base lg:text-base xl:text-lg text-black hover:text-[#9D0004] focus:text-black lg:px-2">
                <NavDropdown />
              </div>

              {/* <li
                className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1"
                data-te-nav-item-ref
              >
                <NavLink aria-current="page" data-te-nav-link-ref to="/">
                  <span className="text-sm sm:text-sm md:text-base lg:text-base xl:text-lg text-black hover:text-[#9D0004] focus:text-black disabled:text-black/30 lg:px-2 [&.active]:text-black/90">
                    Insurance Products
                  </span>
                </NavLink>
              </li> */}
              <li
                className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1"
                data-te-nav-item-ref
              >
                <NavLink aria-current="page" data-te-nav-link-ref to="/aboutus">
                  <span className="text-sm sm:text-sm md:text-base lg:text-base xl:text-lg text-black hover:text-[#9D0004] focus:text-black disabled:text-black/30 lg:px-2 [&.active]:text-black/90">
                    About Us
                  </span>
                </NavLink>
              </li>
              <li
                className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1"
                data-te-nav-item-ref
              >
                <NavLink aria-current="page" data-te-nav-link-ref to="/faq">
                  <span className="text-sm sm:text-sm md:text-base lg:text-base xl:text-lg text-black hover:text-[#9D0004] focus:text-black disabled:text-black/30 lg:px-2 [&.active]:text-black/90">
                    FAQ's
                  </span>
                </NavLink>
              </li>
              <li
                className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1"
                data-te-nav-item-ref
              >
                <NavLink
                  aria-current="page"
                  data-te-nav-link-ref
                  to="/contactus"
                >
                  <span className="text-sm sm:text-sm md:text-base lg:text-base xl:text-lg text-black hover:text-[#9D0004] focus:text-black disabled:text-black/30 lg:px-2 [&.active]:text-black/90">
                    Contact Us
                  </span>
                </NavLink>
              </li>
              {/* {!userData ? (
                <li
                  className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-1"
                  data-te-nav-item-ref
                >
                  <NavLink
                    aria-current="page"
                    data-te-nav-link-ref
                    to="/becomeposp"
                  >
                    <span className="text-sm sm:text-sm md:text-base lg:text-base xl:text-lg text-black hover:text-[#9D0004] focus:text-black disabled:text-black/30 lg:px-2 [&.active]:text-black/90">
                      Become a POSP
                    </span>
                  </NavLink>
                </li>
              ) : (
                ""
              )} */}
            </ul>

            {/* <div className="flex items-center"> */}
            {/* <button
                data-te-ripple-init
                data-te-ripple-color="light"
                className="mr-3 inline-block rounded bg-[#9D0004] px-6 pb-2 pt-2.5 text-sm sm:text-sm md:text-base lg:text-base xl:text-lg font-normal leading-normal text-white shadow-md transition duration-150 ease-in-out"
              >
                Login
              </button> */}
            {/* <LoginDropdown /> */}
            {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="mr-3 inline-block rounded bg-[#9D0004] px-6 pb-2 pt-2.5 text-sm sm:text-sm md:text-base lg:text-base xl:text-lg font-normal leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:bg-[#9D0004] focus:shadow-md focus:outline-none focus:ring-0 active:bg-[#9D0004] active:shadow-md motion-reduce:transition-none"
              >
                Get Quote
              </button> */}
            {/* </div> */}
            {userData ? (
              <>
                <button
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="mr-3 inline-block rounded bg-[#9D0004] px-6 pb-2 pt-2.5 text-sm sm:text-sm md:text-base lg:text-base xl:text-lg font-normal leading-normal text-white shadow-md transition duration-150 ease-in-out"
                  onClick={handleDashboardClick}
                >
                  Dashboard
                </button>
                <button
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="mr-3 inline-block rounded bg-[#9D0004] px-6 pb-2 pt-2.5 text-sm sm:text-sm md:text-base lg:text-base xl:text-lg font-normal leading-normal text-white shadow-md transition duration-150 ease-in-out"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </>
            ) : (
              // <LoginDropdown />
              <></>
            )}
          </div>
        </div>
      </nav>

      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Navbar;
