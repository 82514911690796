import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import Modal from "react-modal";
import moment from "moment";
import Swal from "sweetalert2";
import { Circles } from "react-loader-spinner";

//assets
import digitLogo from "../../assets/digit.svg";
import Discount from "../../assets/discount.svg";
import hdfcLogo from "../../assets/hdfcErgoLogo.jpg";

//components
import Footer from "../../components/Footer";
import PolicyModal from "../../components/Modal/PolicyModal";
import AddOnDrop from "../../components/QuotationDropdowns/Addon";
import AddCover from "../../components/QuotationDropdowns/AddCover";
import DiscountDrop from "../../components/QuotationDropdowns/Discount";

//apis and helper
import { postWithoutToken } from "../../helper/Http";
import apis from "../../helper/allApis";
import { formatAmountInRupees } from "../../helper/utils";

const Toolbar = (props) => {
  const { data, toolbarOptions, onToolbarOptionChange } = props;

  const [selectedMenu, setSelectedMenu] = useState("");
  const {
    maker_description,
    maker_model,
    body_type,
    fuel_type,
    // registered_at,
    registration_date,
    rc_number,
  } = data.data;
  const { preferredPolicy, prevPolicyExpiredate } = data.formData;

  const bikeModel = `${maker_description?.split(" ")[0]} - ${
    maker_model?.split(" ")[0]
  } - ${maker_model?.split(" ")[1]} - ${body_type || null} - ${fuel_type}`;

  const bikeNo = `${rc_number.slice(0, 2)}-${rc_number.slice(
    2,
    4
  )}-${rc_number.slice(4, 6)}-${rc_number.slice(6)}`;

  // const bikeInfo = {
  //   bikeModel,
  //   registerAt: registered_at,
  //   registerDate: registration_date,
  // };

  const isMobile = window.innerWidth <= 767;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <section>
      {/* Bike Details */}
      <article className="lg:p-4 bg-[#FCDADB] p-4">
        <div className="lg:flex lg:justify-evenly lg:p-5">
          <div className="lg:mb-4">
            {/* <p className="mb-2 font-medium">
              HYUNDAI - CRETA - 1.6 - STATION WAGON - PETROL
            </p> */}
            <p className="mb-2 font-medium">{bikeModel || "N/A"}</p>
            <div className="flex">
              {/* <p>MH-03-DK-3310</p> */}
              <p>{bikeNo || "N/A"}</p>
              {isMobile ? (
                <p className="ml-20 lg:mb-2">
                  <span className="font-medium">Previous NCB</span> :{" "}
                  {data.selectedNCB}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          {!isMobile ? (
            <>
              <div className="lg:border-l-2 lg:border-slate-400 hidden lg:block" />
              <div className="mb-4 lg:mb-0">
                {/* <p className="mb-2">
                  <span className="font-medium">Reg Date</span> : 28 Feb, 2020
                </p> */}
                <p className="mb-2">
                  <span className="font-medium">Reg Date</span> :{" "}
                  {moment(registration_date).format("DD MMM, YYYY") || "N/A"}
                </p>
                {/* <p>
                  <span className="font-medium">Exp Date</span> : 23 Feb, 2024
                </p> */}
                <p>
                  <span className="font-medium">Exp Date</span> :{" "}
                  {moment(prevPolicyExpiredate).format("DD MMM, YYYY") || "N/A"}
                </p>
              </div>
              <div className="lg:border-l-2 lg:border-slate-400 hidden lg:block" />
              <div>
                <p className="mb-2">
                  <span className="font-medium">Previous NCB</span> :{" "}
                  {data.selectedNCB}
                </p>
                {/* <p>
                  <span className="font-medium">Policy type</span> :
                  Comprehensive
                </p> */}
                <p>
                  <span className="font-medium">Policy type</span> :
                  {preferredPolicy || "N/A"}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </article>

      {/* Mobile-friendly Toolbar Dropdown */}
      <div className="lg:hidden text-center mx-auto mt-4 mb-4">
        <button
          onClick={toggleDropdown}
          className="bg-white text-[#9D0004] shadow-lg p-3 rounded-xl inline-flex items-center"
        >
          IDV, Addons and more...
        </button>
        {isDropdownVisible && (
          <div className="bg-white py-2 px-4 mt-2 rounded shadow-lg">
            {/* Bike IDV */}
            <div className="flex p-2 items-center">
              <p className="font-medium">Bike IDV: </p>
              <div>
                <input
                  className="shadow appearance-none border rounded p-1 ml-2 text-gray-700 focus:outline-none"
                  id="bikeValue"
                  type="text"
                  placeholder="Set Bike Value"
                  // onChange={(e) =>
                  //   (e.target.value = e.target.value.replace(/\D/g, ""))
                  // }
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter") {
                  //     if (e.target.value !== toolbarOptions.bikeVal) {
                  //       onToolbarOptionChange((prevState) => {
                  //         return { ...prevState, bikeVal: e.target.value };
                  //       });
                  //     }
                  //   }
                  // }}
                  onChange={(e) =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                />
                <button
                  onClick={() => {
                    const inputElement = document.getElementById("bikeValue");
                    const newValue = inputElement.value;

                    if (newValue !== toolbarOptions.bikeVal) {
                      onToolbarOptionChange((prevState) => {
                        return { ...prevState, bikeVal: newValue };
                      });
                    }
                  }}
                  className="bg-[#9D0004] text-white rounded-lg p-1 ml-2"
                >
                  Apply
                </button>
              </div>
            </div>

            {/* Addons */}
            <div className="flex p-2 items-center">
              <p className="font-medium">Addons:</p>
              <div className="ml-2">
                <AddOnDrop
                  selectedMenu={selectedMenu}
                  changeSelectedMenu={() =>
                    setSelectedMenu(selectedMenu === "addon" ? "" : "addon")
                  }
                  toolbarOptions={toolbarOptions}
                  onToolbarOptionChange={onToolbarOptionChange}
                />
              </div>
            </div>

            {/* Additional Covers */}
            <div className="flex p-2 items-center">
              <p className="font-medium">Additional Covers:</p>
              <div className="ml-2">
                <AddCover
                  selectedMenu={selectedMenu}
                  changeSelectedMenu={() =>
                    setSelectedMenu(selectedMenu === "covers" ? "" : "covers")
                  }
                  toolbarOptions={toolbarOptions}
                  onToolbarOptionChange={onToolbarOptionChange}
                />
              </div>
            </div>

            {/* Discounts */}
            <div className="flex p-2 items-center">
              <p className="font-medium">Discounts: </p>
              <div className="ml-2">
                <DiscountDrop
                  selectedMenu={selectedMenu}
                  changeSelectedMenu={() =>
                    setSelectedMenu(
                      selectedMenu === "discounts" ? "" : "discounts"
                    )
                  }
                  toolbarOptions={toolbarOptions}
                  onToolbarOptionChange={onToolbarOptionChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Larger Screen Toolbar Options */}
      {!isMobile ? (
        <article className="md:hidden sm:hidden lg:p-4 lg:flex lg:flex-row lg:justify-around lg:shadow-lg lg:rounded-b-lg">
          {/* Bike IDV */}
          <div className="flex items-center">
            <p className="font-medium">Bike IDV: </p>
            <div>
              <input
                className="shadow appearance-none border rounded p-1 ml-2 text-gray-700 focus:outline-none"
                id="bikeValue"
                type="text"
                placeholder="Set Bike Value"
                // onChange={(e) =>
                //   (e.target.value = e.target.value.replace(/\D/g, ""))
                // }
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     if (e.target.value !== toolbarOptions.bikeVal) {
                //       onToolbarOptionChange((prevState) => {
                //         return { ...prevState, bikeVal: e.target.value };
                //       });
                //     }
                //   }
                // }}
                onChange={(e) =>
                  (e.target.value = e.target.value.replace(/\D/g, ""))
                }
              />
              <button
                onClick={() => {
                  const inputElement = document.getElementById("bikeValue");
                  const newValue = inputElement.value;

                  if (newValue !== toolbarOptions.bikeVal) {
                    onToolbarOptionChange((prevState) => {
                      return { ...prevState, bikeVal: newValue };
                    });
                  }
                }}
                className="bg-[#9D0004] text-white rounded-lg p-1 ml-2"
              >
                Apply
              </button>
            </div>
          </div>

          {/* Addons */}
          <div className="flex items-center">
            <p className="font-medium">Addons:</p>
            <div className="ml-2">
              <AddOnDrop
                selectedMenu={selectedMenu}
                changeSelectedMenu={() =>
                  setSelectedMenu(selectedMenu === "addon" ? "" : "addon")
                }
                toolbarOptions={toolbarOptions}
                onToolbarOptionChange={onToolbarOptionChange}
              />
            </div>
          </div>

          {/* Additional Covers */}
          <div className="flex items-center">
            <p className="font-medium">Additional Covers:</p>
            <div className="ml-2">
              <AddCover
                selectedMenu={selectedMenu}
                changeSelectedMenu={() =>
                  setSelectedMenu(selectedMenu === "covers" ? "" : "covers")
                }
                toolbarOptions={toolbarOptions}
                onToolbarOptionChange={onToolbarOptionChange}
              />
            </div>
          </div>

          {/* Discounts */}
          <div className="flex items-center">
            <p className="font-medium">Discounts: </p>
            <div className="ml-2">
              <DiscountDrop
                selectedMenu={selectedMenu}
                changeSelectedMenu={() =>
                  setSelectedMenu(
                    selectedMenu === "discounts" ? "" : "discounts"
                  )
                }
                toolbarOptions={toolbarOptions}
                onToolbarOptionChange={onToolbarOptionChange}
              />
            </div>
          </div>
        </article>
      ) : (
        ""
      )}
    </section>
  );
};

const PlanCard = (props) => {
  const {
    vehicleNo,
    data,
    vehicleInfo,
    insuranceType,
    ncbValue,
    madeClaim,
    toolbarOptions,
    previousPolicyType,
  } = props;

  const [policyModal, setPolicyModal] = useState(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: isMobile ? "80%" : "40%", // Adjust width based on the screen size
      maxWidth: "100%", // Set a maximum width for the modal
      height: "max-content",
      transform: "translate(-50%, -50%)",
      borderRadius: "3%",
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.34)",
    },
  };

  function openPolicyModal() {
    setPolicyModal(true);
  }
  function closePolicyModal() {
    setPolicyModal(false);
  }

  const insurerName = data.INSURER_NAME;
  let imgSrc;
  switch (insurerName) {
    // case "SHRIRAM":
    //   imgSrc = ShriramGi;
    //   break;
    case "GODIGIT":
      imgSrc = digitLogo;
      break;
    case "HDFC":
      imgSrc = hdfcLogo;
      break;
    default:
      // imgSrc = digitLogo;
      imgSrc = Discount;
      break;
  }

  const addonAndAdditionalName = (name) => {
    const words = name.split(/(?=[A-Z])/);
    const formattedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedName;
  };

  const handleQuoteSelection = () => {
    navigate("../kycstep", {
      state: {
        INSURER_NAME: insurerName,
        planData: data,
        vehicleInfo,
        vehicleNo,
        insuranceType,
        ncbValue,
        madeClaim,
        toolbarOptions,
        previousPolicyType,
      },
    });
  };

  return (
    <div className="bg-white rounded-2xl shadow-md border-2 w-4/5 lg:w-[40%] p-2 mx-auto mb-10 lg:p-5 lg:m-10">
      <div className="flex flex-col items-center">
        <img
          className="h-20 w-40 object-cover mb-4"
          src={imgSrc}
          alt="companyLogo"
        />
        <p className="text-[#00000094] lg:text-lg text-center mt-2 lg:mt-0">
          {insuranceType}
        </p>
      </div>
      <div className="lg:flex lg:justify-around mt-4">
        <div className="lg:flex lg:flex-col items-center">
          <p className="lg:mb-4 mb-1 text-center">
            {/* Claims Settled : <span className="text-green-500">96%</span> */}
            Claims Settled : <span className="text-green-500">{data.CSR}%</span>
          </p>
          <div className="flex justify-center">
            <button onClick={openPolicyModal} className="mb-1 lg:mb-0">
              <p className="text-[#9D0004] font-medium text-center">
                View Policy details
              </p>
            </button>
          </div>
        </div>
        <div className="lg:flex lg:flex-col items-center">
          {insuranceType !== "TP only" ? (
            <p className="lg:mb-4 text-center">
              {/* IDV Cover : <span className="font-medium">8,41,589</span> */}
              IDV Cover :{" "}
              <span className="font-medium">
                {formatAmountInRupees(data.bikeValue)}
                {/* {formatAmountInRupees(data.bikeMaxIDVValue)} */}
              </span>
            </p>
          ) : (
            <p></p>
          )}
          <div className="text-center lg:mt-0 mt-4 lg:mb-0 mb-4">
            <button
              className="bg-[#9D0004] flex gap-4 items-center px-8 text-white font-medium rounded-xl p-3 mx-auto"
              type="button"
              onClick={handleQuoteSelection}
            >
              {formatAmountInRupees(data.totalAmount)}
              <img src={Discount} alt="discountIcon" />
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={policyModal}
        onRequestClose={closePolicyModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <PolicyModal
          data={data}
          vehicleNo={vehicleNo}
          vehicleInfo={vehicleInfo}
          closePolicyModal={closePolicyModal}
        />
      </Modal>
      {data?.AddOnFilteredData?.length ? (
        <div className="flex w-full basis-1/5 bg-[#FCDADB] rounded-lg mt-3">
          {/* labels */}
          <div className="flex h-auto w-full">
            {data?.AddOnFilteredData && (
              <div className="p-2 w-full">
                {data.AddOnFilteredData.map((ele) =>
                  ele.value !== 0 ? (
                    <div className="flex" key={ele.value}>
                      <ul className="w-full flex justify-between py-1 text-md">
                        <li className="font-medium">
                          {addonAndAdditionalName(ele.name)} -{" "}
                        </li>
                        <li className="mr-5">
                          {typeof ele.value === "string"
                            ? ele.value.replace("INR", " ").trim()
                            : ele.value}
                        </li>
                      </ul>
                    </div>
                  ) : null
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const BikeInsuranceQuotes = () => {
  const [quoteData, setQuoteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toolbarOptions, setToolbarOptions] = useState({
    bikeVal: "",
    antiTheft: false,
    voluntaryExcess: "",
    addOnPackages: [],
    additionalCovers: [],
  });
  const location = useLocation();
  const {
    data,
    formData,
    insuranceType,
    previousPolicyType,
    ncbValue,
    selectedNCB,
    madeClaim,
    selectedMake,
    selectedModel,
    selectedFuelType,
    selectedVariant,
    selectedCity,
  } = location.state;

  useEffect(() => {
    async function fetchData() {
      try {
        setQuoteData([]);
        const dummyReqData = {
          vehicleNo: data.rc_number,
          registrationDate: formData.registrationDate,
          policyHolderType: "INDIVIDUAL",
          previousinsurer: data.insurance_company,
          prevPolicyExpireDate: formData.prevPolicyExpiredate,
          odPolicyExpiredate: formData.odPolicyExpiredate,
          tpPolicyExpiredate: formData.tpPolicyExpiredate,
          // digitVehicleCode: formData.digitVehicleCode,
          digitVehicleCode: formData.digitVehicleCode,
          hdfcBikeCode: formData.hdfcBikeCode,
          insuranceType: formData.preferredPolicy,
          previousPolicyType: formData.previousPolicyType,
          ncbValue: formData.ncb,
          madeClaim: madeClaim,

          selectedNCB: selectedNCB,
          selectedMake: selectedMake?.value,
          selectedModel: selectedModel?.value,
          selectedFuelType: selectedFuelType?.value,
          selectedVariant: selectedVariant?.value,
          selectedCity: selectedCity?.value,

          // options
          IDV_of_Vehicle: toolbarOptions.bikeVal,
          AntiTheftYN: toolbarOptions.antiTheft,
          VoluntaryExcess: toolbarOptions.voluntaryExcess,
          addOnPackages: toolbarOptions.addOnPackages,
          additionalCovers: toolbarOptions.additionalCovers,
        };

        setLoading(true);
        const res = await postWithoutToken(
          `${apis.BIKE_GENERATE_QUOTE}`,
          dummyReqData
        );
        if (res?.data?.status === false) {
          Swal.fire({
            icon: "error",
            title: res?.data?.message,
            text: res?.data?.data?.MessageResult?.ErrorMessage,
          });
          setLoading(true);
        } else {
          const data = res?.data;
          setQuoteData(data.data);
          setLoading(false);
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "some error occured",
        });
        console.log(err);
      }
    }
    fetchData();
  }, [
    toolbarOptions,
    data,
    formData,
    insuranceType,
    previousPolicyType,
    ncbValue,
    madeClaim,
    selectedNCB,
    selectedMake,
    selectedModel,
    selectedFuelType,
    selectedVariant,
    selectedCity,
  ]);

  return (
    <div>
      <Toolbar
        data={location.state}
        toolbarOptions={toolbarOptions}
        onToolbarOptionChange={setToolbarOptions}
      />

      <section className="lg:p-10">
        <article>
          <h1 className="font-medium text-xl lg:text-3xl ml-5 lg:ml-5">
            {quoteData?.length > 0
              ? `${quoteData?.length} Plans found`
              : "Fetching plans..."}
          </h1>

          {loading ? (
            <div className="flex justify-center items-center mt-10">
              <Circles
                height={80}
                width={80}
                color="#F8363B"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <div className="lg:flex lg:flex-wrap lg:justify-center lg:mt-8 mt-6">
              {quoteData?.map((card) => (
                <PlanCard
                  key={card.quoteId}
                  data={card}
                  vehicleNo={data.rc_number}
                  vehicleInfo={formData}
                  allCards={card}
                  insuranceType={insuranceType}
                  ncbValue={ncbValue}
                  madeClaim={madeClaim}
                  toolbarOptions={toolbarOptions}
                  previousPolicyType={previousPolicyType}
                />
              ))}
            </div>
          )}
        </article>
      </section>

      <Footer />
    </div>
  );
};

export default BikeInsuranceQuotes;
