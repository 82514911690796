import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { ThreeDots } from "react-loader-spinner";

//components
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollToTop";
import CommonModal from "../../components/Modal/CarInsurance/CommonModal";
import { getVehicleDetails } from "../../apis/verificationApis";
import { carNumbervalidationSchema } from "../../helper/validationSchema";

//assets
import carLogo from "../../assets/carIns.svg";
import carBlob from "../../assets/carBlob.svg";
import carQuotesBlob from "../../assets/carQuotesBlob.svg";
import signingContract from "../../assets/signingContract.svg";
import carOd from "../../assets/carOd.svg";
import carComp from "../../assets/carComp.svg";

const WhyWasanCard = ({ title, description }) => {
  return (
    <div className="flex flex-col bg-[#FCDADB] rounded-lg p-4 w-full md:w-[48%] lg:w-[30%]">
      <h4 className="text-[#9D0004] text-lg mb-4">{title}</h4>
      <p className="text-[#00000094]">{description}</p>
    </div>
  );
};

const InsuranceDetailParagraph = ({ details }) => {
  return <p className="text-[#00000094] lg:text-lg">{details}</p>;
};

const CarInsurance = () => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const isMobile = windowWidth <= 768;
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const isMobile = window.innerWidth <= 767;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: isMobile ? "80%" : "40%", // Adjust width based on the screen size
      maxWidth: "100%", // Set a maximum width for the modal
      height: "max-content",
      transform: "translate(-50%, -50%)",
      borderRadius: "3%",
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.34)",
    },
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [carNumber, setCarNumber] = useState("");
  const [validationError, setValidationError] = useState("");
  const [carDetails, setCarDetails] = useState({});
  const [loading, setLoading] = useState(false);

  function openModal(modalType) {
    setModalContent(modalType);
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const handleViewQuoteSubmit = async (e) => {
    e.preventDefault();
    try {
      await carNumbervalidationSchema.validate({ carNumber });
      setValidationError("");
    } catch (error) {
      setValidationError(error.message);
    }
    setLoading(true);

    const res = await getVehicleDetails(carNumber.split("-").join(""));
    // if (res.data.seat_capacity <= 2) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Invalid Car Number",
    //     confirmButtonText: "Try With Other Number",
    //     confirmButtonColor: "#9D0004",
    //   }).then(() => {
    //     window.location.reload();
    //   });
    //   setLoading(true);
    // } else
    if (res.ok) {
      setCarDetails(res.data);
      setLoading(false);
      openModal("modalwithcarno");
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to fetch Vehicle details",
        text: res?.data,
      });
      console.log(res);
      setLoading(true);
    }
  };

  //to enter car registration no. in correct form
  const formatCarNumber = (value) => {
    // removes any characters from the value that are not alphanumeric by using the replace method with a regular expression
    let formattedValue = value.replace(/[^A-Za-z0-9]/g, "").toUpperCase();
    // This condition checks if the length of the formattedValue is greater than 10 characters. If it exceeds 10, it is trimmed using substr to keep only the first 10 characters.
    if (formattedValue.length > 10) {
      formattedValue = formattedValue.substr(0, 10);
    }
    if (formattedValue.length > 2) {
      formattedValue = formattedValue.replace(
        /^([A-Z]{2})([A-Z0-9]*)$/,
        "$1-$2"
      );
    }
    if (formattedValue.length > 5) {
      formattedValue = formattedValue.replace(
        /([A-Z]{2}-\d{2})([A-Z0-9]*)$/,
        "$1-$2"
      );
    }
    if (formattedValue.length > 8) {
      formattedValue = formattedValue.replace(
        /([A-Z]{2}-\d{2}-[A-Z]{2})([A-Z0-9]*)$/,
        "$1-$2"
      );
    }
    setCarNumber(formattedValue);
  };

  const handleChange = (e) => {
    if (e.target.value.length < carNumber.length) {
      setCarNumber(e.target.value);
    } else {
      formatCarNumber(e.target.value);
    }
  };

  return (
    <>
      <div>
        {/* Car number input */}
        <section className="p-2">
          <article className="flex flex-col justify-center items-center lg:flex-row lg:justify-around">
            <div className="lg:mt-16">
              <img alt="" src={carLogo} />
            </div>
            <div className="flex flex-col items-center lg:items-start">
              <h1 className="text-[#9D0004] font-medium text-2xl lg:text-4xl lg:mb-4">
                Car Insurance
              </h1>
              <p className="text-[#00000094] text-lg text-center w-[70%] lg:text-xl lg:text-start mb-4 lg:mb-8">
                Compare & Secure Your Ideal Car Insurance
              </p>
              {!loading ? (
                <form
                  onSubmit={handleViewQuoteSubmit}
                  className="flex flex-col items-center lg:items-start"
                >
                  <input
                    className="bg-slate-50 p-3 border-2 rounded-lg outline-none w-[130%] lg:w-[180%]"
                    type="text"
                    required={true}
                    value={carNumber}
                    onChange={handleChange}
                    placeholder="Enter Car Number"
                  />
                  {validationError && (
                    <div className="text-red-500 text-sm text-center">
                      {validationError}
                    </div>
                  )}
                  <button
                    id="modalwithcarno"
                    type="submit"
                    className="bg-[#9D0004] font-medium text-white p-3 mt-6 rounded-lg w-[130%] lg:p-3 lg:w-[180%]"
                  >
                    View Quote
                  </button>
                  <div className="flex justify-between mt-10">
                    {/* <button
                           onClick={() => openModal("modalwithoutcarno")}
                           className="text-sm cursor-pointer underline"
                           id="modalwithoutcarno"
                         >
                           Continue without car number
                         </button> */}

                    <button
                      onClick={() => openModal("modalnewcar")}
                      className="text-base text-[#9D0004] underline cursor-pointer"
                      id="modalnewcar"
                    >
                      New Car? click here
                    </button>
                  </div>
                </form>
              ) : (
                <div className="flex flex-col justify-center items-center mt-10">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#F8363B"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                  <button
                    className="text-white p-2  bg-[#9D0004] rounded-xl"
                    onClick={() => window.location.reload()}
                  >
                    Try Again
                  </button>
                </div>
              )}
            </div>
          </article>
        </section>

        {/* Car insurance detail */}
        <section
          style={{
            backgroundImage: `url(${isMobile ? carQuotesBlob : carBlob})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
          className="p-2"
        >
          <article className="mt-28 lg:mt-44">
            <div className="flex flex-col justify-center items-center p-2">
              <h2 className="text-[#9D0004] text-lg font-medium text-center lg:text-3xl">
                Comprehensive Car Insurance Coverage
              </h2>
              <p className="text-[#00000094] text-center p-2 lg:text-xl lg:w-[90%]">
                Shield yourself from unexpected expenses and safeguard your
                vehicle with car insurance. Car insurance offers you complete
                financial security from accidents to theft, natural calamities
                to fire, and even third-party liabilities. Stay on the safe side
                of the lawn with mandatory car insurance for all four-wheelers
                as per the Motor Vehicles Act of 1988. Drive with absolute
                confidence, knowing that our reliable and comprehensive
                insurance coverage well protects them.
              </p>
            </div>

            <div className="flex flex-row justify-between p-2 lg:p-10 lg:ml-10 lg:mr-10 mt-2 lg:justify-center">
              <div className="flex items-center justify-center w-full">
                <img alt="" src={signingContract} />
              </div>
              <div className="ml-5">
                <h3 className="text-[#9D0004] font-medium mb-2 lg:text-2xl">
                  Third Party Insurance
                </h3>
                <p className="text-[#00000094] lg:text-lg">
                  Protect yourself and others on the road with third-party
                  insurance – a must-have motor insurance policy.
                </p>
                {window.innerWidth > 640 ? (
                  <InsuranceDetailParagraph
                    details="This ensures you're financially and legally protected in the
                event of an accident involving your insured vehicle and a third
                party. You can drive with confidence knowing that you're covered
                for any third-party injuries, fatalities, or property damages
                caused by your vehicle."
                  />
                ) : null}
              </div>
            </div>
            <div className="p-2">
              {/* <p className="text-[#00000094] text-start">
                This ensures you're financially and legally protected in the
                event of an accident involving your insured vehicle and a third
                party. You can drive with confidence knowing that you're covered
                for any third-party injuries, fatalities, or property damages
                caused by your vehicle.
              </p> */}
              {window.innerWidth < 640 ? (
                <InsuranceDetailParagraph
                  details="This ensures you're financially and legally protected in the
                event of an accident involving your insured vehicle and a third
                party. You can drive with confidence knowing that you're covered
                for any third-party injuries, fatalities, or property damages
                caused by your vehicle."
                />
              ) : null}
            </div>

            <div className="flex flex-row justify-between p-2 lg:p-10 lg:ml-10 lg:mr-10 mt-2 lg:justify-center">
              <div className="mr-5">
                <h3 className="text-[#9D0004] font-medium mb-2 lg:text-2xl">
                  Comprehensive Insurance
                </h3>
                <p className="text-[#00000094] lg:text-lg">
                  Drive worry-free and enjoy ultimate protection with our
                  comprehensive car insurance policy.
                </p>
                {window.innerWidth > 640 ? (
                  <InsuranceDetailParagraph
                    details="From unexpected accidents to fire incidents, our all-inclusive
                coverage has you covered. Not only does it shield your car from
                damages, but it also takes care of any liabilities toward third
                parties."
                  />
                ) : null}
              </div>
              <div className="flex items-center justify-center w-full">
                <img alt="" src={carComp} />
              </div>
            </div>
            <div className="p-2">
              {/* <p className="text-[#00000094] text-start">
                From unexpected accidents to fire incidents, our all-inclusive
                coverage has you covered. Not only does it shield your car from
                damages, but it also takes care of any liabilities toward third
                parties.
              </p> */}
              {window.innerWidth < 640 ? (
                <InsuranceDetailParagraph
                  details="From unexpected accidents to fire incidents, our all-inclusive
                coverage has you covered. Not only does it shield your car from
                damages, but it also takes care of any liabilities toward third
                parties."
                />
              ) : null}
            </div>

            <div className="flex flex-row justify-between p-2 lg:p-10 lg:ml-10 lg:mr-10 mt-2 lg:justify-center">
              <div className="flex items-center justify-center w-full">
                <img alt="" src={carOd} />
              </div>
              <div className="ml-5">
                <h3 className="text-[#9D0004] font-medium mb-2 lg:text-2xl">
                  Own Damage Insurance
                </h3>
                <p className="text-[#00000094] lg:text-lg">
                  Say goodbye to hefty car maintenance costs with our affordable
                  own-damage car insurance.
                </p>
                {window.innerWidth > 640 ? (
                  <InsuranceDetailParagraph
                    details="Protect your vehicle from accidental damage, theft, and
                fire-related losses without breaking the bank. Unlike
                comprehensive car insurance, this comes at a lower cost,
                allowing you to choose a shorter duration that suits your needs."
                  />
                ) : null}
              </div>
            </div>
            <div className="p-2">
              {/* <p className="text-[#00000094] text-start">
                Protect your vehicle from accidental damage, theft, and
                fire-related losses without breaking the bank. Unlike
                comprehensive car insurance, this comes at a lower cost,
                allowing you to choose a shorter duration that suits your needs.
              </p> */}
              {window.innerWidth < 640 ? (
                <InsuranceDetailParagraph
                  details="Protect your vehicle from accidental damage, theft, and
                fire-related losses without breaking the bank. Unlike
                comprehensive car insurance, this comes at a lower cost,
                allowing you to choose a shorter duration that suits your needs."
                />
              ) : null}
            </div>
          </article>
        </section>

        {/* Why Choose K Wasan Car Insurance? */}
        <section className="p-2">
          <article className="mt-4 lg:mt-20">
            <h3 className="text-[#9D0004] text-lg font-medium text-center lg:text-3xl">
              Why Choose K Wasan Car Insurance?
            </h3>
            <p className="text-[#00000094] text-center mt-2 lg:mt-5 mb-5 lg:mb-10 lg:text-lg">
              Experience the Difference
            </p>
            <div className="flex flex-wrap justify-between gap-6 lg:gap-14 ml-5 mr-5 lg:ml-10 lg:mr-10">
              <WhyWasanCard
                title="Renew Policy in 2 Minutes"
                description="Renew your car insurance in 2 minutes flat with K Wasan Car
                Insurance. Say goodbye to paperwork and enjoy our hassle-free
                online platform. Log in, update, pay, and hit the road with
                peace of mind. Fast, easy, and convenient. Experience the
                convenience and speed of renewing with us."
              />
              <WhyWasanCard
                title="Choose From 20+ Insurers"
                description="We've got a lineup of 20+ top insurers vying for your
                attention. Take your pick from the best in the biz and
                customize your car insurance just the way you like it. Whether
                you want unbeatable rates, extensive coverage, or exclusive
                benefits, we've got the perfect match for you."
              />
              <WhyWasanCard
                title="Premiums that Fit Your Wallet"
                description="We get it – affordability matters. That's why at K Wasan Car
                Insurance, we offer pocket-friendly premiums that won't leave
                you strapped for cash. Get comprehensive coverage that fits
                your budget like a glove. Get maximum value for your
                hard-earned money to safeguard your car without burning a hole
                in your wallet."
              />
              <WhyWasanCard
                title="24X7 Customer Assistance"
                description="You matter to us, and we're here to prove it. Our team of
                friendly and expert customer support agents is committed to
                providing outstanding service. We're always ready to address
                your questions and concerns and provide personalized
                assistance tailored to your needs around the clock."
              />
              <WhyWasanCard
                title="No More Claim Worries"
                description="We understand how daunting it can be to deal with claims.
                That's why we've gone the extra mile to make the process easy,
                simple, and hassle-free for you. Our dedicated claims team is
                here to support you, ensuring a smooth and efficient
                experience when it comes to filing a claim."
              />
              <WhyWasanCard
                title="360 Degrees of Extensive Protection"
                description="Have full confidence in the extensive coverage we offer. From
               accidents and theft to natural disasters and third-party
               liabilities, we've got you covered in all situations, ensuring
               your peace of mind on the road."
              />
            </div>
          </article>
        </section>

        <div className="lg:mr-14">
          <ScrollToTop />
        </div>
        <Footer />

        {setModalIsOpen && validationError === "" && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <CommonModal
              data={carDetails}
              closeModal={closeModal}
              modalType={modalContent}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default CarInsurance;
