import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Discount = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { toolbarOptions, onToolbarOptionChange } = props;

  const [antiTheft, setAntiTheft] = useState(0);
  const [voluntaryExcess, setVoluntaryExcess] = useState(0);

  const handleDiscountApply = (e) => {
    e.preventDefault();
    const toolbarObj = {
      ...toolbarOptions,
      antiTheft: antiTheft,
      voluntaryExcess: voluntaryExcess,
    };
    onToolbarOptionChange(toolbarObj);
  };

  return (
    <div className="relative inline-block">
      <button
        type="button"
        className="w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-sm font-medium text-gray-700 flex items-center justify-between"
        onClick={props.changeSelectedMenu}
      >
        <span className="mr-2">
          {selectedOptions.length > 0
            ? `${selectedOptions.length} selected`
            : "Select Discount"}
        </span>
        <FontAwesomeIcon
          icon={
            props.selectedMenu === "discounts" ? faChevronUp : faChevronDown
          }
          className="h-4 w-4 text-gray-600"
        />
      </button>
      {props.selectedMenu === "discounts" && (
        <form onSubmit={handleDiscountApply}>
          <div className="absolute w-max mt-2 py-2 bg-white border border-gray-300 rounded-md shadow-lg">
            <>
              <div className="flex flex-col px-6 py-4">
                <p className="font-semibold mb-1">Anti Theft Device :</p>
                <div className="flex items-center space-x-5">
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id="anti-theft-yes"
                      name="anti-theft-group"
                      className="h-4 w-4 text-blue-500"
                      value={1}
                      checked={antiTheft === 1}
                      onChange={(e) => setAntiTheft(Number(e.target.value))}
                    />
                    <label htmlFor="yes" className="text-md">
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id="anti-theft-no"
                      name="anti-theft-group"
                      className="h-4 w-4 text-blue-500"
                      value={0}
                      checked={antiTheft === 0}
                      onChange={(e) => setAntiTheft(Number(e.target.value))}
                    />
                    <label htmlFor="no" className="text-md">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-6 py-1">
                <p className="font-semibold mb-1 mt-1">Voluntry Access :</p>
                <div className="flex items-center justify-between space-y-2 space-x-8">
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id="voluntary-no"
                      name="voluntary-group"
                      className="h-4 w-4 text-blue-500"
                      value={0}
                      checked={voluntaryExcess === 0}
                      onChange={(e) =>
                        setVoluntaryExcess(Number(e.target.value))
                      }
                    />
                    <label htmlFor="no" className="text-md">
                      No
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id="voluntary-2500"
                      name="voluntary-group"
                      className="h-4 w-4 text-blue-500"
                      value={2500}
                      checked={voluntaryExcess === 2500}
                      onChange={(e) =>
                        setVoluntaryExcess(Number(e.target.value))
                      }
                    />
                    <label htmlFor="yes" className="text-md">
                      2500
                    </label>
                  </div>
                </div>
                <div className="flex items-center justify-between space-y-2 space-x-8">
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id="voluntary-5000"
                      name="voluntary-group"
                      className="h-4 w-4 text-blue-500"
                      value={5000}
                      checked={voluntaryExcess === 5000}
                      onChange={(e) =>
                        setVoluntaryExcess(Number(e.target.value))
                      }
                    />
                    <label htmlFor="no" className="text-md">
                      5000
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id="voluntary-7500"
                      name="voluntary-group"
                      className="h-4 w-4 text-blue-500"
                      value={7500}
                      checked={voluntaryExcess === 7500}
                      onChange={(e) =>
                        setVoluntaryExcess(Number(e.target.value))
                      }
                    />
                    <label htmlFor="yes" className="text-md">
                      7500
                    </label>
                  </div>
                </div>
                <div className="flex items-center justify-between space-y-2 space-x-8">
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id="voluntary-15000"
                      name="voluntary-group"
                      className="h-4 w-4 text-blue-500"
                      value={15000}
                      checked={voluntaryExcess === 15000}
                      onChange={(e) =>
                        setVoluntaryExcess(Number(e.target.value))
                      }
                    />
                    <label htmlFor="yes" className="text-md">
                      15000
                    </label>
                  </div>
                </div>
              </div>
            </>
            <button
              type="submit"
              // onClick={(e) => {
              //   e.preventDefault();
              // }}
              className="bg-[#9D0004] mx-7 p-2 rounded-lg text-white"
            >
              Apply
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Discount;
