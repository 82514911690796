import apis from "../helper/allApis";
import { postWithoutToken } from "../helper/Http";

/**
 * @description - Quotation related APIs
 * @auth - required
 * @route /quote
 **/

const getLoginDetails = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.WASAN_LOGIN}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const contactUsEmailSubmit = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.CONTACTUSEMAIL}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

export { getLoginDetails, contactUsEmailSubmit };
