import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// apis
import { getLoginDetails } from "../apis/loginApis";
// assets
import WasanLogo from "../assets/WasanLogo.svg";
import LoginImg from "../assets/loginImg.svg";

const WasanLogin = () => {
  const navigate = useNavigate();
  const [empPANNO, setEmpPANNO] = useState("");
  const [empCode, setEmpCode] = useState("");

  const handleLogin = async () => {
    try {
      if (!empPANNO || !empCode) {
        console.error("Please enter User ID and Employee Code");
        return;
      }

      const loginDetails = await getLoginDetails({
        empType: "RM",
        empCode: parseInt(empCode, 10),
        empPANNO,
      });

      if (loginDetails && loginDetails?.data === null) {
        Swal.fire({
          icon: "error",
          title: loginDetails?.message,
          confirmButtonText: "Close",
          confirmButtonColor: "#9D0004",
        });
        return;
      }

      // if (loginDetails && loginDetails.message === "user logged in") {
      //   localStorage.setItem("userData", JSON.stringify(loginDetails?.data[0]));
      // } else {
      //   console.log("User is not an admin.");
      // }
      if (loginDetails && loginDetails.message === "user logged in") {
        const { empPANNO, empEmail } = loginDetails?.data[0];

        localStorage.setItem(
          "userData",
          JSON.stringify({ empPANNO, empEmail })
        );
      } else {
        console.log("User is not an admin.");
      }

      const empFullName = loginDetails?.data[0]?.empName
        ?.toLowerCase()
        ?.replace(/\b\w/g, (char) => char.toUpperCase());

      Swal.fire({
        icon: "success",
        title: `Welcome, ${empFullName}`,
        confirmButtonText: "Next",
        confirmButtonColor: "#9D0004",
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      Swal.hideLoading();
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#faf5ea] to-transparent flex lg:flex-row flex-col justify-center items-center">
      <div className="p-10 mt-20 bg-white rounded-2xl shadow-lg lg:w-1/4">
        <div className="flex justify-center mb-4">
          <img src={WasanLogo} alt="" />
        </div>
        <h2 className="text-[#9D0004] font-medium lg:text-xl text-lg text-center mb-5">
          Employee Login
        </h2>
        <div className="space-y-4">
          <input
            type="text"
            required
            placeholder="User ID"
            className="block text-sm lg:py-3 lg:px-4 px-2 py-1  rounded-xl w-full border bg-slate-100 outline-none"
            value={empPANNO}
            onChange={(e) => setEmpPANNO(e.target.value)}
          />
          <input
            type="text"
            required
            placeholder="Employee Code"
            className="block text-sm lg:py-3 lg:px-4 px-2 py-1  rounded-xl w-full border bg-slate-100 outline-none"
            value={empCode}
            onChange={(e) => setEmpCode(e.target.value)}
          />
        </div>
        <div className="text-center mt-6">
          <button
            onClick={handleLogin}
            className="lg:p-2 p-1 w-3/5 lg:text-lg text-white bg-[#9D0004] rounded-xl"
          >
            Sign In
          </button>
        </div>
      </div>
      <div className="lg:pt-20 lg:ml-10">
        <img
          className="lg:w-max lg:h-max w-60 h-60 lg:mt-0 mt-5"
          src={LoginImg}
          alt=""
        />
      </div>
      <div className="absolute w-60 h-60 rounded-xl bg-slate-400 -top-5 -left-16 transform rotate-45 hidden md:block"></div>
      <div className="absolute w-48 h-48 rounded-xl bg-slate-400 -bottom-6 -right-10 transform rotate-12 hidden md:block"></div>
      <div className="w-40 h-40 absolute bg-slate-400 rounded-full top-0 right-12 hidden md:block"></div>
      <div className="w-20 h-40 absolute bg-slate-400 rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
    </div>
  );
};

export default WasanLogin;
