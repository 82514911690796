import React from "react";

import ModalWithCarNo from "./ModalWithCarNo";
import ModalNewCar from "./ModalNewCar";

const CommonModal = ({ data, closeModal, modalType }) => {
  if (modalType === "modalwithcarno") {
    return <ModalWithCarNo data={data} closeModal={closeModal} />;
  } else {
    return <ModalNewCar closeModal={closeModal} />;
  }
};

export default CommonModal;
