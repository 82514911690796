// All backend apis and routes

let apis = {
  // server DEV  URL = "https://uat.kwasaninsurance.com/api/v1"  ip = "34.93.239.135",
  // server PROD URL = "https://prod.kwasaninsurance.com/api/v1" ip = "34.93.185.243",
  // local_dev = "http://localhost:5000/api/v1",
  // local_prod = "http://localhost:8080/api/v1",

  baseUrl: `${process.env.REACT_APP_SERVER}/api/v1`,

  // common apis
  FETCH_VEHICLE_DETAILS: "/verification/fetchVehicle",
  FETCH_VEHICLE_CODE: "/verification/fetchVehicleCode",
  FETCH_NEW_VEHICLE_CODE: "/verification/fetchNewVehicleCode",
  LOGIN: "/customer/login",
  CONTACTUSEMAIL: "/customer/contactUsEmail",
  WASAN_LOGIN: "/admin/wasanlogin",
  KYC_DETAILS: "/verification/kycVerify",
  OTP_VERIFY: "/customer/verify-otp",
  POLICY_DOC: "/generatePolicyPdf",

  // car apis
  CAR_GENERATE_QUOTE: "/car/quickQuote",
  CAR_GENERATE_QUOTE_NEW: "/car/quickQuoteNewVehicle",
  CREATE_PROPOSAL_CAR: "/car/createProposal",
  CREATE_PROPOSAL_CAR_NEW: "/car/createCarProposalNewCar",
  PAYMENT_CAR: "/car/payment",
  KYC_CAR: "/car/kyc_status",
  GET_USER_DATA: "http://localhost:8080/api/v1/car/getData",
  DOWNLOAD_CAR_PDF: "/car/download_pdf",

  // bike apis
  BIKE_GENERATE_QUOTE: "/bike/quickQuote",
  BIKE_GENERATE_QUOTE_NEW: "/bike/quickBikeQuoteNew",
  CREATE_PROPOSAL_BIKE: "/bike/createProposal",
  CREATE_PROPOSAL_BIKE_NEW: "/bike/createBikeProposalNewBike",
  PAYMENT_BIKE: "/bike/payment",
  KYC_BIKE: "/bike/kyc_status",

  // health apis
  INDIVIDUAL_QUICK_QUOTE_HEALTH: "/health/individualQuickHealthQuote",
  FAMILY_QUICK_QUOTE_HEALTH: "/health/familyQuickHealthQuote",
  PEHCHAN_KYC: "/health/hdfc/fetchKYCDetailsHealthIns",
  CREATE_PROPOSAL_HEALTH: "/health/createHealthProposal",
  PAYMENT_HEALTH: "/health/payment",
  KYC_HEALTH: "/health/kyc_status",
  DOWNLOAD_PDF: "/health/download_pdf",

  //test apis
  HDFC_PAYMENT: "/health/hdfcPayment",
  PAYMENT_STATUS_UPDATE:
    "https://uat.kwasaninsurance.com/api/v1/health/paymentStatusUpdate",
  // use the url below when using localhost
  // "http://localhost:8080/api/v1/health/paymentStatusUpdate",

  //new vehicle apis
  VEHICLE_MAKE: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/makes`,
  VEHICLE_MODEL: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/models/:make`,
  VEHICLE_FUELTYPE: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/fuel/:make/:model`,
  VEHICLE_VARIANT: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/variants/:make/:model/:fuelType`,

  BIKE_MAKE: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/bikemakes`,
  BIKE_MODEL: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/bikemodels/:make`,
  BIKE_VARIANT: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/bikevariants/:make/:model`,

  CITYANDRTODETIAL: `${process.env.REACT_APP_SERVER}/api/v1/newVehicle/cityAndRtoCode`,
};
export default apis;
