import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const pospFormvalidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be more than 2 characters")
    .required("Name is Required"),
  contactNo: yup
    .string()
    .matches(phoneRegExp, "Contact Number is not valid")
    .required("Contact Number is required"),
  emailId: yup
    .string()
    .email("Invalid email address")
    .required("Email ID is Required"),
});

const carNumbervalidationSchema = yup.object().shape({
  carNumber: yup.string().required("Car Registration Number is required"),
  // .matches(/^[A-Z]{2}-\d{2}-[A-Z]{2}-\d{4}$/, "Invalid RTO"),
});

const basicDetailsValidationSchema = yup.object().shape({
  registrationnumber: yup.string().required("Registration Number is required"),
  customername: yup.string().required("Customer Name is required"),
  email: yup.string().email("Invalid Email").required("Email is required"),
  mobilenumber: yup
    .string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
  gender: yup.string().required("Gender is required"),
  maritalstatus: yup.string().required("Marital Status is required"),
  dob: yup.date().required("Date of Birth is required"),
  pannumber: yup
    .string()
    .required("PAN Number is required")
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN Number"),
  //  gstnumber: yup
  //     .string()
  //     .required("GST Number is required")
  //     .matches(
  //        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[A-Z]{1}[0-9A-Z]{1}$/,
  //        "Invalid GST Number"
  //     ),
  carNumber: yup.string().required("Car Registration Number is required"),
  // .matches(/^[A-Z]{2}-\d{2}-[A-Z]{2}-\d{4}$/, "Invalid RTO"),
});

const bikeNumbervalidationSchema = yup.object().shape({
  bikeNumber: yup.string().required("Bike Registration Number is required"),
  // .matches(/^[A-Z]{2}-\d{2}-[A-Z]{2}-\d{4}$/, "Invalid RTO"),
});

export {
  basicDetailsValidationSchema,
  pospFormvalidationSchema,
  carNumbervalidationSchema,
  bikeNumbervalidationSchema,
};
