import React, { useContext } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import Datepicker from "react-tailwindcss-datepicker";

import { CarInsuranceFormContext } from "../../../pages/CarInsurance/CarInsuranceProposal";

function NomineeDetails() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(CarInsuranceFormContext);

  const formik = useFormik({
    initialValues: {
      nomineename: formData.nomineename,
      nomineedob: formData.nomineedob,
      nomineerelation: formData.nomineerelation,
    },
    onSubmit: (values) => {
      const data = { ...formData, ...values };
      setFormData(data);
      setActiveStepIndex(activeStepIndex + 1);
    },
  });

  const relationOptions = [
    { value: "SON", label: "Son" },
    { value: "DAUGHTER", label: "Daughter" },
    { value: "FATHER", label: "Father" },
    { value: "MOTHER", label: "Mother" },
    { value: "BROTHER", label: "Brother" },
    { value: "SISTER", label: "Sister" },
    { value: "SPOUSE", label: "Spouse" },
    { value: "HUSBAND", label: "Husband" },
  ];

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col space-y-4 lg:space-y-6"
    >
      <div className="lg:flex lg:space-x-8 lg:space-y-0 space-y-4">
        <div className="flex flex-col items-start lg:mb-0">
          <label className="font-medium text-gray-900">Nominee Name</label>
          <input
            type="text"
            name="nomineename"
            required
            className="rounded-md border-2 lg:p-2 p-1 w-full lg:w-96 bg-slate-100 outline-none"
            placeholder="Enter Nominee Name"
            value={formik.values.nomineename}
            onChange={formik.handleChange}
          />
          {formik.touched.nomineename && formik.errors.nomineename ? (
            <div className="text-red-500">{formik.errors.nomineename}</div>
          ) : null}
        </div>

        <div className="flex flex-col items-start">
          <label className="font-medium text-gray-900">Nominee Relation</label>
          <Select
            className="p-1 w-full lg:w-96"
            name="nomineerelation"
            required
            options={relationOptions}
            value={{
              value: formik.values.nomineerelation,
              label: formik.values.nomineerelation,
            }}
            onChange={(opt) => {
              console.log(opt);
              formik.setFieldValue("nomineerelation", opt.value);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "#F8FAFC",
              }),
            }}
          />
          {formik.touched.nomineerelation && formik.errors.nomineerelation ? (
            <div className="text-red-500">{formik.errors.nomineerelation}</div>
          ) : null}
        </div>
      </div>

      <div className="lg:flex lg:space-x-8">
        <div className="flex flex-col items-start mb-2">
          <label className="font-medium text-gray-900">
            Nominee Date of Birth
          </label>
          <Datepicker
            popoverDirection="up"
            inputClassName="w-full rounded-md bg-slate-100 border-2 lg:p-2 p-1 lg:w-96 focus:outline-none"
            primaryColor={"rose"}
            useRange={false}
            asSingle={true}
            value={{
              startDate: formik.values.nomineedob,
              endDate: formik.values.nomineedob,
            }}
            maxDate={new Date()}
            onChange={(selectDate) => {
              formik.setFieldValue("nomineedob", selectDate.startDate);
            }}
          />
          {formik.touched.nomineedob && formik.errors.nomineedob ? (
            <div className="text-red-500">{formik.errors.nomineedob}</div>
          ) : null}
        </div>
      </div>

      <div className="flex lg:flex-row lg:justify-between flex-col items-center lg:mb-4">
        <div className="lg:mb-0 mb-6 lg:mt-0 mt-4">
          <button
            className="bg-[#9D0004] text-white font-medium rounded-xl focus:outline-none p-2 w-32 lg:py-3 lg:w-40"
            onClick={() => setActiveStepIndex(activeStepIndex - 1)}
          >
            Previous
          </button>
        </div>

        <div>
          <button
            className="bg-[#9D0004] text-white font-medium rounded-xl focus:outline-none p-2 w-32 lg:py-3 lg:w-40"
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
}

export default NomineeDetails;
