import axios from "axios";
import apis from "../helper/allApis";
import { postWithoutToken } from "../helper/Http";

/**
 * @description - Quotation related APIs
 * @auth - required
 * @route /quote
 **/

const getCarQuotes = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.CAR_GENERATE_QUOTE}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const createCarProposal = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.CREATE_PROPOSAL_CAR}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: res.data.data,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const createCarProposalNew = async (reqObj) => {
  try {
    const res = await postWithoutToken(
      `${apis.CREATE_PROPOSAL_CAR_NEW}`,
      reqObj
    );

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: res.data.data,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const carPayment = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.PAYMENT_CAR}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const hdfcCheckSum = async (paymentId, paymentAmount) => {
  try {
    // "http://202.191.196.210/UAT/PaymentUtilitiesService/PaymentUtilities.asmx/GenerateRequestChecksum",
    const hdfcObj = {
      checkSumURL:
        "https://heapp21.hdfcergo.com/UAT/PaymentUtilitiesService/PaymentUtilities.asmx/GenerateRequestChecksum",
      TransactionNo: paymentId,
      TotalAmount: paymentAmount,
      AppID: "10308",
      SubscriptionID: "S000000344",
      // SuccessUrl: "http://localhost:8080/api/v1/health/hdfcPayment",
      // FailureUrl: "http://localhost:8080/api/v1/health/hdfcPayment",
      //use this uat one when pushing changes
      SuccessUrl: "https://uat.kwasaninsurance.com/api/v1/health/hdfcPayment",
      FailureUrl: "https://prod.kwasaninsurance.com/api/v1/health/hdfcPayment",
      Source: "POST",
    };
    const checkSumReq = await axios.get(
      `${hdfcObj.checkSumURL}?TransactionNo=${hdfcObj.TransactionNo}&TotalAmount=${hdfcObj.TotalAmount}&AppID=${hdfcObj.AppID}&SubscriptionID=${hdfcObj.SubscriptionID}&SuccessUrl=${hdfcObj.SuccessUrl}&FailureUrl=${hdfcObj.FailureUrl}&Source=${hdfcObj.Source}`
    );
    const xmlResponse = checkSumReq.data;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, "text/xml");
    const valueElement = xmlDoc.querySelector("string");
    const checkSum = valueElement.textContent;
    hdfcObj.checkSum = checkSum;
    return {
      ok: true,
      data: hdfcObj,
    };
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      message: "Error occured at at hdfc payment process",
    };
  }
};

const carKycStatus = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.KYC_CAR}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res?.data?.status,
        data: null,
        message: res?.data?.message,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err?.response?.data?.message,
    };
  }
};

const getPolicyPdf = async (reqObj) => {
  try {
    const res = await postWithoutToken(`${apis.DOWNLOAD_CAR_PDF}`, reqObj);

    if (res?.data?.status) {
      return {
        ok: res.data.status,
        data: res.data.data,
        message: res.data.message,
      };
    } else {
      return {
        ok: res.data.status,
        data: null,
        message: res.data.errors[0].msg,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      ok: false,
      data: null,
      message: err.response.data.message,
    };
  }
};

export {
  getCarQuotes,
  createCarProposal,
  createCarProposalNew,
  carPayment,
  carKycStatus,
  hdfcCheckSum,
  getPolicyPdf,
};
