import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const listOptions = [
  {
    id: 1,
    value: "personalAccidentCover",
    description: "Owner Driver PA Cover",
  },
  {
    id: 2,
    value: "unNamedPassengerCover",
    description: "UnNamed Passenger Cover",
  },
  {
    id: 1,
    value: "paidDriverLegalLiability",
    description: "Paid Driver Legal Liability",
  },
  {
    id: 1,
    value: "electricalAccessories",
    description: "Electrical Accessories",
  },
  {
    id: 1,
    value: "nonElectricalAccessories",
    description: "Non Electrical Accessories",
  },
];

const AddCover = (props) => {
  const { toolbarOptions, onToolbarOptionChange } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionToggle = (optionId) => {
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
    } else {
      setSelectedOptions([...selectedOptions, optionId]);
    }
  };

  const handleAddCoverApply = () => {
    console.log(selectedOptions);
    const toolbarObj = {
      ...toolbarOptions,
      additionalCovers: selectedOptions,
    };
    onToolbarOptionChange(toolbarObj);
  };

  return (
    <div className="lg:relative inline-block">
      <button
        type="button"
        className="w-full bg-white border border-gray-300 rounded-md shadow-sm p-2 text-sm font-medium text-gray-700 flex items-center justify-between"
        onClick={props.changeSelectedMenu}
      >
        <span className="mr-2">
          {selectedOptions.length > 0
            ? `${selectedOptions.length} selected`
            : "Select Covers"}
        </span>
        <FontAwesomeIcon
          icon={props.selectedMenu === "covers" ? faChevronUp : faChevronDown}
          className="h-4 w-4 text-gray-600"
        />
      </button>
      {props.selectedMenu === "covers" && (
        <div className="lg:absolute w-max mt-2 py-2 bg-white border border-gray-300 rounded-md shadow-lg">
          {listOptions.map((option) => (
            <label
              key={option.id}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              <input
                type="checkbox"
                className="form-checkbox mr-2"
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleOptionToggle(option.value)}
              />
              <span className="text-gray-800">{option.description}</span>
            </label>
          ))}
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAddCoverApply();
            }}
            className="bg-[#9D0004] mx-7 p-2 rounded-lg text-white"
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

export default AddCover;
