import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

//assets
import CloseIcon from "../../../assets/closeIcon.svg";

//apis
import apis from "../../../helper/allApis";
import { getNewVehicleCodeFromDb } from "../../../apis/verificationApis";

const ModalNewBike = ({ closeModal }) => {
  const isMobile = window.innerWidth <= 767;
  const navigate = useNavigate();
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [makesOptions, setMakesOptions] = useState([]);
  const [modelsOptions, setModelsOptions] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);
  const [cityAndRTODetails, setCityAndRTODetails] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await getNewVehicleCodeFromDb({
      make: selectedMake?.value,
      model: selectedModel?.value,
      fuelType: "PETROL",
      variant: selectedVariant?.value,
    });
    if (res.ok) {
      const newDigitVehicleCode =
        res.data.newDigitVehicleCodeForVariant[0]?.vehicleCode;
      const newHdfcBikeCode =
        res.data.newHdfcBikeCodeForVariant[0]?.VEHICLEMODELCODE.toString();
      // const newMagmaBikeCode =
      //   res.data.newMagmaBikeCodeForVariant[0]?.VEHICLEMODELCODE;

      // const mgVehicleBodyType =
      //   res.data.newMagmaBikeCodeForVariant[0]?.VEHICLEBODYTYPEDESCRIPTION;
      // const mgCarryingCap =
      //   res.data.newMagmaBikeCodeForVariant[0]?.CARRYINGCAPACITY;
      // const mgVehicleBodyTypeCode =
      //   res.data.newMagmaBikeCodeForVariant[0]?.BODYTYPECODE;
      // const mgSegmentType =
      //   res.data.newMagmaBikeCodeForVariant[0]?.TXT_SEGMENTTYPE;
      // const mgModelNameCode =
      //   res.data.newMagmaBikeCodeForVariant[0]?.VEHICLEMODELNAMECODE;
      // const mgVariant = res.data.newMagmaBikeCodeForVariant[0]?.TXT_VARIANT;
      // const mgCubicCapacity =
      //   res.data.newMagmaBikeCodeForVariant[0]?.CUBICCAPACITY;
      // const mgSeatingCapacity =
      //   res.data.newMagmaBikeCodeForVariant[0]?.SEATINGCAPACITY;

      // let newMgBikeMake = selectedMake?.value;
      // let newMgBikeModel = selectedModel?.value;

      if (newDigitVehicleCode || newHdfcBikeCode) {
        console.log("Digit Vehicle Code:", newDigitVehicleCode);
        console.log("HDFC Vehicle Code:", newHdfcBikeCode);
        // console.log("Magma Vehicle Code:", newMagmaBikeCode);

        navigate("newquotes", {
          state: {
            selectedMake: selectedMake?.value,
            selectedModel: selectedModel?.value,
            selectedVariant: selectedVariant?.value,
            selectedCity: selectedCity?.value,
            // vehicleCode,
            newDigitVehicleCode,
            newHdfcBikeCode,
            // newMagmaBikeCode,
            // mgVehicleBodyType,
            // mgCarryingCap,
            // mgVehicleBodyTypeCode,
            // mgSegmentType,
            // mgModelNameCode,
            // mgVariant,
            // mgCubicCapacity,
            // mgSeatingCapacity,
            // newMgBikeMake,
            // newMgBikeModel,
          },
        });
      } else {
        console.log("Vehicle Code not available");
        Swal.fire({
          icon: "error",
          title:
            "Vehicle Code missing - Please contact K Wasan Insurance team at 022 6683 2222 to process your insurance need in minutes",
        });
      }
    } else {
      console.log(res);
      Swal.fire({
        icon: "error",
        title:
          "Vehicle Code missing - Please contact K Wasan Insurance team at 022 6683 2222 to process your insurance need in minutes",
      });
    }
  };

  // Fetch makes from the backend when the component mounts
  useEffect(() => {
    axios.get(apis.BIKE_MAKE).then((response) => {
      setMakesOptions(
        response.data.map((make) => ({ value: make, label: make }))
      );
    });
  }, []);

  // Fetch models for the selected make
  useEffect(() => {
    if (selectedMake) {
      axios
        .get(apis.BIKE_MODEL.replace(":make", selectedMake.value))
        .then((response) => {
          setModelsOptions(
            response.data.map((model) => ({ value: model, label: model }))
          );
        });
    }
  }, [selectedMake]);

  // Fetch variants for the selected make, model, and fuel type
  useEffect(() => {
    if (selectedMake && selectedModel) {
      axios
        .get(
          apis.BIKE_VARIANT.replace(":make", selectedMake.value).replace(
            ":model",
            selectedModel.value
          )
        )
        .then((response) => {
          setVariantOptions(
            response.data.map((variant) => ({ value: variant, label: variant }))
          );
        });
    }
  }, [selectedMake, selectedModel]);

  // Fetch City and RTO details
  useEffect(() => {
    axios.get(apis.CITYANDRTODETIAL).then((response) => {
      const formattedData = response.data.map((item) => ({
        value: `${item.registered_city_name} - ${item.region_code}`,
        label: `${item.registered_city_name} - ${item.region_code}`,
      }));
      setCityAndRTODetails(formattedData);
    });
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="flex justify-between">
        <h2 className="lg:text-3xl text-lg font-medium text-[#9D0004] lg:ml-10">
          New Bike
        </h2>
        <button onClick={closeModal} className="text-[#9CA3AF] lg:mr-10">
          <img className="lg:w-7 lg:h-7 w-5 h-6" src={CloseIcon} alt="" />
        </button>
      </div>

      <div className="flex lg:justify-center justify-start lg:m-5">
        <form onSubmit={handleSubmit} className={isMobile ? "w-full" : ""}>
          <label className="text-[#4B4B4B]" htmlFor="make">
            Make
          </label>
          <Select
            options={makesOptions}
            value={selectedMake}
            onChange={setSelectedMake}
            isSearchable
            className="lg:w-96 m-1"
          />

          <label className="text-[#4B4B4B]" htmlFor="model">
            Model
          </label>
          <Select
            options={modelsOptions}
            value={selectedModel}
            onChange={setSelectedModel}
            isSearchable
            className="lg:w-96 m-1"
          />

          <label className="text-[#4B4B4B]" htmlFor="variant">
            Variant
          </label>
          <Select
            options={variantOptions}
            value={selectedVariant}
            onChange={setSelectedVariant}
            isSearchable
            className="lg:w-96 m-1"
          />

          <label className="text-[#4B4B4B]" htmlFor="city">
            City and RTO Code
          </label>
          <Select
            options={cityAndRTODetails}
            value={selectedCity}
            onChange={setSelectedCity}
            isSearchable
            className="lg:w-96 m-1"
          />

          <button
            onClick={handleSubmit}
            className="py-3 mt-10 w-full text-lg text-white bg-[#9D0004] rounded-xl"
          >
            View Quote
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModalNewBike;
