import React from "react";

const BecomePosp = () => {
  return (
    <>
      <div className="container">
        <h1>Become Posp</h1>
      </div>
    </>
  );
};

export default BecomePosp;
